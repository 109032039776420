enifed('ember-glimmer/component-managers/root', ['exports', 'ember-babel', '@glimmer/runtime', 'ember-metal', 'ember-debug', 'ember-glimmer/utils/curly-component-state-bucket', 'ember-glimmer/component-managers/curly'], function (exports, _emberBabel, _runtime, _emberMetal, _emberDebug, _curlyComponentStateBucket, _curly) {
  'use strict';

  exports.RootComponentDefinition = undefined;

  var RootComponentManager = function (_CurlyComponentManage) {
    (0, _emberBabel.inherits)(RootComponentManager, _CurlyComponentManage);

    function RootComponentManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _CurlyComponentManage.apply(this, arguments));
    }

    RootComponentManager.prototype.create = function (environment, definition, args, dynamicScope) {
      var component = definition.ComponentClass.create();

      var finalizer = (0, _emberMetal._instrumentStart)('render.component', _curly.initialRenderInstrumentDetails, component);

      dynamicScope.view = component;

      // We usually do this in the `didCreateElement`, but that hook doesn't fire for tagless components
      if (component.tagName === '') {
        if (environment.isInteractive) {
          component.trigger('willRender');
        }

        component._transitionTo('hasElement');

        if (environment.isInteractive) {
          component.trigger('willInsertElement');
        }
      }

      return new _curlyComponentStateBucket.default(environment, component, args.named.capture(), finalizer);
    };

    return RootComponentManager;
  }(_curly.default);

  var ROOT_MANAGER = new RootComponentManager();

  exports.RootComponentDefinition = function (_ComponentDefinition) {
    (0, _emberBabel.inherits)(RootComponentDefinition, _ComponentDefinition);

    function RootComponentDefinition(instance) {

      var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, '-root', ROOT_MANAGER, {
        class: instance.constructor,
        create: function () {
          return instance;
        }
      }));

      _this2.template = undefined;
      _this2.args = undefined;
      return _this2;
    }

    return RootComponentDefinition;
  }(_runtime.ComponentDefinition);
});