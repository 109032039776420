enifed('ember-glimmer/component-managers/abstract', ['exports'], function (exports) {
  'use strict';

  var AbstractManager = function () {
    function AbstractManager() {

      this.debugStack = undefined;
    }

    AbstractManager.prototype.prepareArgs = function () {
      return null;
    };

    AbstractManager.prototype.create = function () {};

    AbstractManager.prototype.layoutFor = function () {};

    AbstractManager.prototype.getSelf = function (bucket) {
      return bucket;
    };

    AbstractManager.prototype.didCreateElement = function () {};

    AbstractManager.prototype.didRenderLayout = function () {};

    AbstractManager.prototype.didCreate = function () {};

    AbstractManager.prototype.getTag = function () {
      return null;
    };

    AbstractManager.prototype.update = function () {};

    AbstractManager.prototype.didUpdateLayout = function () {};

    AbstractManager.prototype.didUpdate = function () {};

    AbstractManager.prototype.getDestructor = function () {};

    return AbstractManager;
  }();

  exports.default = AbstractManager;
});