enifed('ember/index', ['exports', 'require', 'ember-environment', 'node-module', 'ember-utils', 'container', 'ember-metal', 'ember/features', 'ember-debug', 'backburner', 'ember-console', 'ember-runtime', 'ember-glimmer', 'ember/version', 'ember-views', 'ember-routing', 'ember-application', 'ember-extension-support'], function (exports, _require2, _emberEnvironment, _nodeModule, _emberUtils, _container, _emberMetal, _features, _emberDebug, _backburner, _emberConsole, _emberRuntime, _emberGlimmer, _version, _emberViews, _emberRouting, _emberApplication, _emberExtensionSupport) {
  'use strict';

  exports.VERSION = undefined;

  // ember-utils exports


  // ****ember-metal****


  // ****ember-environment****
  _emberMetal.default.getOwner = _emberUtils.getOwner;
  _emberMetal.default.setOwner = _emberUtils.setOwner;
  _emberMetal.default.generateGuid = _emberUtils.generateGuid;
  _emberMetal.default.GUID_KEY = _emberUtils.GUID_KEY;
  _emberMetal.default.guidFor = _emberUtils.guidFor;
  _emberMetal.default.inspect = _emberUtils.inspect;
  _emberMetal.default.makeArray = _emberUtils.makeArray;
  _emberMetal.default.canInvoke = _emberUtils.canInvoke;
  _emberMetal.default.tryInvoke = _emberUtils.tryInvoke;
  _emberMetal.default.wrap = _emberUtils.wrap;
  _emberMetal.default.applyStr = _emberUtils.applyStr;
  _emberMetal.default.uuid = _emberUtils.uuid;
  _emberMetal.default.assign = _emberUtils.assign;

  // container exports
  _emberMetal.default.Container = _container.Container;
  _emberMetal.default.Registry = _container.Registry;

  // need to import this directly, to ensure the babel feature
  // flag plugin works properly


  var computed = _emberMetal.computed,
      testing;
  computed.alias = _emberMetal.alias;
  _emberMetal.default.computed = computed;
  _emberMetal.default.ComputedProperty = _emberMetal.ComputedProperty;
  _emberMetal.default.cacheFor = _emberMetal.cacheFor;

  _emberMetal.default.assert = _emberDebug.assert;
  _emberMetal.default.warn = _emberDebug.warn;
  _emberMetal.default.debug = _emberDebug.debug;
  _emberMetal.default.deprecate = _emberDebug.deprecate;
  _emberMetal.default.deprecateFunc = _emberDebug.deprecateFunc;
  _emberMetal.default.runInDebug = _emberDebug.runInDebug;
  /**
    @public
    @class Ember.Debug
  */
  _emberMetal.default.Debug = {
    registerDeprecationHandler: _emberDebug.registerDeprecationHandler,
    registerWarnHandler: _emberDebug.registerWarnHandler
  };
  _emberMetal.default.merge = _emberMetal.merge;

  _emberMetal.default.instrument = _emberMetal.instrument;
  _emberMetal.default.subscribe = _emberMetal.instrumentationSubscribe;
  _emberMetal.default.Instrumentation = {
    instrument: _emberMetal.instrument,
    subscribe: _emberMetal.instrumentationSubscribe,
    unsubscribe: _emberMetal.instrumentationUnsubscribe,
    reset: _emberMetal.instrumentationReset
  };

  _emberMetal.default.Error = _emberDebug.Error;
  _emberMetal.default.META_DESC = _emberMetal.META_DESC;
  _emberMetal.default.meta = _emberMetal.meta;
  _emberMetal.default.get = _emberMetal.get;
  _emberMetal.default.getWithDefault = _emberMetal.getWithDefault;
  _emberMetal.default._getPath = _emberMetal._getPath;
  _emberMetal.default.set = _emberMetal.set;
  _emberMetal.default.trySet = _emberMetal.trySet;
  _emberMetal.default.FEATURES = _features.FEATURES;
  _emberMetal.default.FEATURES.isEnabled = _emberDebug.isFeatureEnabled;
  _emberMetal.default._Cache = _emberMetal.Cache;
  _emberMetal.default.on = _emberMetal.on;
  _emberMetal.default.addListener = _emberMetal.addListener;
  _emberMetal.default.removeListener = _emberMetal.removeListener;
  _emberMetal.default._suspendListener = _emberMetal.suspendListener;
  _emberMetal.default._suspendListeners = _emberMetal.suspendListeners;
  _emberMetal.default.sendEvent = _emberMetal.sendEvent;
  _emberMetal.default.hasListeners = _emberMetal.hasListeners;
  _emberMetal.default.watchedEvents = _emberMetal.watchedEvents;
  _emberMetal.default.listenersFor = _emberMetal.listenersFor;
  _emberMetal.default.isNone = _emberMetal.isNone;
  _emberMetal.default.isEmpty = _emberMetal.isEmpty;
  _emberMetal.default.isBlank = _emberMetal.isBlank;
  _emberMetal.default.isPresent = _emberMetal.isPresent;
  _emberMetal.default.run = _emberMetal.run;
  _emberMetal.default._ObserverSet = _emberMetal.ObserverSet;
  _emberMetal.default.propertyWillChange = _emberMetal.propertyWillChange;
  _emberMetal.default.propertyDidChange = _emberMetal.propertyDidChange;
  _emberMetal.default.overrideChains = _emberMetal.overrideChains;
  _emberMetal.default.beginPropertyChanges = _emberMetal.beginPropertyChanges;
  _emberMetal.default.endPropertyChanges = _emberMetal.endPropertyChanges;
  _emberMetal.default.changeProperties = _emberMetal.changeProperties;
  _emberMetal.default.platform = {
    defineProperty: true,
    hasPropertyAccessors: true
  };
  _emberMetal.default.defineProperty = _emberMetal.defineProperty;
  _emberMetal.default.watchKey = _emberMetal.watchKey;
  _emberMetal.default.unwatchKey = _emberMetal.unwatchKey;
  _emberMetal.default.removeChainWatcher = _emberMetal.removeChainWatcher;
  _emberMetal.default._ChainNode = _emberMetal.ChainNode;
  _emberMetal.default.finishChains = _emberMetal.finishChains;
  _emberMetal.default.watchPath = _emberMetal.watchPath;
  _emberMetal.default.unwatchPath = _emberMetal.unwatchPath;
  _emberMetal.default.watch = _emberMetal.watch;
  _emberMetal.default.isWatching = _emberMetal.isWatching;
  _emberMetal.default.unwatch = _emberMetal.unwatch;
  _emberMetal.default.destroy = _emberMetal.destroy;
  _emberMetal.default.libraries = _emberMetal.libraries;
  _emberMetal.default.OrderedSet = _emberMetal.OrderedSet;
  _emberMetal.default.Map = _emberMetal.Map;
  _emberMetal.default.MapWithDefault = _emberMetal.MapWithDefault;
  _emberMetal.default.getProperties = _emberMetal.getProperties;
  _emberMetal.default.setProperties = _emberMetal.setProperties;
  _emberMetal.default.expandProperties = _emberMetal.expandProperties;
  _emberMetal.default.NAME_KEY = _emberUtils.NAME_KEY;
  _emberMetal.default.addObserver = _emberMetal.addObserver;
  _emberMetal.default.observersFor = _emberMetal.observersFor;
  _emberMetal.default.removeObserver = _emberMetal.removeObserver;
  _emberMetal.default._suspendObserver = _emberMetal._suspendObserver;
  _emberMetal.default._suspendObservers = _emberMetal._suspendObservers;
  _emberMetal.default.required = _emberMetal.required;
  _emberMetal.default.aliasMethod = _emberMetal.aliasMethod;
  _emberMetal.default.observer = _emberMetal.observer;
  _emberMetal.default.immediateObserver = _emberMetal._immediateObserver;
  _emberMetal.default.mixin = _emberMetal.mixin;
  _emberMetal.default.Mixin = _emberMetal.Mixin;
  _emberMetal.default.bind = _emberMetal.bind;
  _emberMetal.default.Binding = _emberMetal.Binding;
  _emberMetal.default.isGlobalPath = _emberMetal.isGlobalPath;

  Object.defineProperty(_emberMetal.default, 'ENV', {
    get: function () {
      return _emberEnvironment.ENV;
    },

    enumerable: false
  });

  /**
   The context that Ember searches for namespace instances on.
  
   @private
   */
  Object.defineProperty(_emberMetal.default, 'lookup', {
    get: function () {
      return _emberEnvironment.context.lookup;
    },
    set: function (value) {
      _emberEnvironment.context.lookup = value;
    },

    enumerable: false
  });

  _emberMetal.default.EXTEND_PROTOTYPES = _emberEnvironment.ENV.EXTEND_PROTOTYPES;

  // BACKWARDS COMPAT ACCESSORS FOR ENV FLAGS
  Object.defineProperty(_emberMetal.default, 'LOG_STACKTRACE_ON_DEPRECATION', {
    get: function () {
      return _emberEnvironment.ENV.LOG_STACKTRACE_ON_DEPRECATION;
    },
    set: function (value) {
      _emberEnvironment.ENV.LOG_STACKTRACE_ON_DEPRECATION = !!value;
    },

    enumerable: false
  });

  Object.defineProperty(_emberMetal.default, 'LOG_VERSION', {
    get: function () {
      return _emberEnvironment.ENV.LOG_VERSION;
    },
    set: function (value) {
      _emberEnvironment.ENV.LOG_VERSION = !!value;
    },

    enumerable: false
  });

  Object.defineProperty(_emberMetal.default, 'LOG_BINDINGS', {
    get: function () {
      return _emberEnvironment.ENV.LOG_BINDINGS;
    },
    set: function (value) {
      _emberEnvironment.ENV.LOG_BINDINGS = !!value;
    },

    enumerable: false
  });

  /**
    A function may be assigned to `Ember.onerror` to be called when Ember
    internals encounter an error. This is useful for specialized error handling
    and reporting code.
  
    ```javascript
    Ember.onerror = function(error) {
      Em.$.ajax('/report-error', 'POST', {
        stack: error.stack,
        otherInformation: 'whatever app state you want to provide'
      });
    };
    ```
  
    Internally, `Ember.onerror` is used as Backburner's error handler.
  
    @event onerror
    @for Ember
    @param {Exception} error the error object
    @public
  */
  Object.defineProperty(_emberMetal.default, 'onerror', {
    get: _emberMetal.getOnerror,
    set: _emberMetal.setOnerror,
    enumerable: false
  });

  /**
    An empty function useful for some operations. Always returns `this`.
  
    @method K
    @return {Object}
    @public
    @deprecated
  */
  function deprecatedEmberK() {
    return this;
  }

  Object.defineProperty(_emberMetal.default, 'K', {
    get: function () {
      false && !false && (0, _emberDebug.deprecate)('Ember.K is deprecated in favor of defining a function inline.', false, {
        id: 'ember-metal.ember-k',
        until: '3.0.0',
        url: 'https://emberjs.com/deprecations/v2.x#toc_code-ember-k-code'
      });

      return deprecatedEmberK;
    }
  });

  Object.defineProperty(_emberMetal.default, 'testing', {
    get: _emberDebug.isTesting,
    set: _emberDebug.setTesting,
    enumerable: false
  });

  _emberMetal.default._Backburner = _backburner.default;

  _emberMetal.default.Logger = _emberConsole.default;

  // ****ember-runtime****

  _emberMetal.default.String = _emberRuntime.String;
  _emberMetal.default.Object = _emberRuntime.Object;
  _emberMetal.default._RegistryProxyMixin = _emberRuntime.RegistryProxyMixin;
  _emberMetal.default._ContainerProxyMixin = _emberRuntime.ContainerProxyMixin;
  _emberMetal.default.compare = _emberRuntime.compare;
  _emberMetal.default.copy = _emberRuntime.copy;
  _emberMetal.default.isEqual = _emberRuntime.isEqual;
  _emberMetal.default.inject = _emberRuntime.inject;
  _emberMetal.default.Array = _emberRuntime.Array;
  _emberMetal.default.Comparable = _emberRuntime.Comparable;
  _emberMetal.default.Enumerable = _emberRuntime.Enumerable;
  _emberMetal.default.ArrayProxy = _emberRuntime.ArrayProxy;
  _emberMetal.default.ObjectProxy = _emberRuntime.ObjectProxy;
  _emberMetal.default.ActionHandler = _emberRuntime.ActionHandler;
  _emberMetal.default.CoreObject = _emberRuntime.CoreObject;
  _emberMetal.default.NativeArray = _emberRuntime.NativeArray;
  _emberMetal.default.Copyable = _emberRuntime.Copyable;
  _emberMetal.default.Freezable = _emberRuntime.Freezable;
  _emberMetal.default.FROZEN_ERROR = _emberRuntime.FROZEN_ERROR;
  _emberMetal.default.MutableEnumerable = _emberRuntime.MutableEnumerable;
  _emberMetal.default.MutableArray = _emberRuntime.MutableArray;
  _emberMetal.default.TargetActionSupport = _emberRuntime.TargetActionSupport;
  _emberMetal.default.Evented = _emberRuntime.Evented;
  _emberMetal.default.PromiseProxyMixin = _emberRuntime.PromiseProxyMixin;
  _emberMetal.default.Observable = _emberRuntime.Observable;
  _emberMetal.default.typeOf = _emberRuntime.typeOf;
  _emberMetal.default.isArray = _emberRuntime.isArray;
  _emberMetal.default.Object = _emberRuntime.Object;
  _emberMetal.default.onLoad = _emberRuntime.onLoad;
  _emberMetal.default.runLoadHooks = _emberRuntime.runLoadHooks;
  _emberMetal.default.Controller = _emberRuntime.Controller;
  _emberMetal.default.ControllerMixin = _emberRuntime.ControllerMixin;
  _emberMetal.default.Service = _emberRuntime.Service;
  _emberMetal.default._ProxyMixin = _emberRuntime._ProxyMixin;
  _emberMetal.default.RSVP = _emberRuntime.RSVP;
  _emberMetal.default.Namespace = _emberRuntime.Namespace;

  // ES6TODO: this seems a less than ideal way/place to add properties to Ember.computed
  computed.empty = _emberRuntime.empty;
  computed.notEmpty = _emberRuntime.notEmpty;
  computed.none = _emberRuntime.none;
  computed.not = _emberRuntime.not;
  computed.bool = _emberRuntime.bool;
  computed.match = _emberRuntime.match;
  computed.equal = _emberRuntime.equal;
  computed.gt = _emberRuntime.gt;
  computed.gte = _emberRuntime.gte;
  computed.lt = _emberRuntime.lt;
  computed.lte = _emberRuntime.lte;
  computed.oneWay = _emberRuntime.oneWay;
  computed.reads = _emberRuntime.oneWay;
  computed.readOnly = _emberRuntime.readOnly;
  computed.deprecatingAlias = _emberRuntime.deprecatingAlias;
  computed.and = _emberRuntime.and;
  computed.or = _emberRuntime.or;
  computed.any = _emberRuntime.any;

  computed.sum = _emberRuntime.sum;
  computed.min = _emberRuntime.min;
  computed.max = _emberRuntime.max;
  computed.map = _emberRuntime.map;
  computed.sort = _emberRuntime.sort;
  computed.setDiff = _emberRuntime.setDiff;
  computed.mapBy = _emberRuntime.mapBy;
  computed.filter = _emberRuntime.filter;
  computed.filterBy = _emberRuntime.filterBy;
  computed.uniq = _emberRuntime.uniq;

  computed.uniqBy = _emberRuntime.uniqBy;
  computed.union = _emberRuntime.union;
  computed.intersect = _emberRuntime.intersect;
  computed.collect = _emberRuntime.collect;

  /**
    Defines the hash of localized strings for the current language. Used by
    the `Ember.String.loc()` helper. To localize, add string values to this
    hash.
  
    @property STRINGS
    @for Ember
    @type Object
    @private
  */
  Object.defineProperty(_emberMetal.default, 'STRINGS', {
    configurable: false,
    get: _emberRuntime.getStrings,
    set: _emberRuntime.setStrings
  });

  /**
    Whether searching on the global for new Namespace instances is enabled.
  
    This is only exported here as to not break any addons.  Given the new
    visit API, you will have issues if you treat this as a indicator of
    booted.
  
    Internally this is only exposing a flag in Namespace.
  
    @property BOOTED
    @for Ember
    @type Boolean
    @private
  */
  Object.defineProperty(_emberMetal.default, 'BOOTED', {
    configurable: false,
    enumerable: false,
    get: _emberRuntime.isNamespaceSearchDisabled,
    set: _emberRuntime.setNamespaceSearchDisabled
  });

  _emberMetal.default.Component = _emberGlimmer.Component;
  _emberGlimmer.Helper.helper = _emberGlimmer.helper;
  _emberMetal.default.Helper = _emberGlimmer.Helper;
  _emberMetal.default.Checkbox = _emberGlimmer.Checkbox;
  _emberMetal.default.TextField = _emberGlimmer.TextField;
  _emberMetal.default.TextArea = _emberGlimmer.TextArea;
  _emberMetal.default.LinkComponent = _emberGlimmer.LinkComponent;

  if (_emberEnvironment.ENV.EXTEND_PROTOTYPES.String) {
    String.prototype.htmlSafe = function () {
      return (0, _emberGlimmer.htmlSafe)(this);
    };
  }

  var EmberHandlebars = _emberMetal.default.Handlebars = _emberMetal.default.Handlebars || {};
  var EmberHTMLBars = _emberMetal.default.HTMLBars = _emberMetal.default.HTMLBars || {};
  var EmberHandleBarsUtils = EmberHandlebars.Utils = EmberHandlebars.Utils || {};

  Object.defineProperty(EmberHandlebars, 'SafeString', {
    get: _emberGlimmer._getSafeString
  });

  EmberHTMLBars.template = EmberHandlebars.template = _emberGlimmer.template;
  EmberHandleBarsUtils.escapeExpression = _emberGlimmer.escapeExpression;
  _emberRuntime.String.htmlSafe = _emberGlimmer.htmlSafe;

  _emberRuntime.String.isHTMLSafe = _emberGlimmer.isHTMLSafe;

  /**
    Global hash of shared templates. This will automatically be populated
    by the build tools so that you can store your Handlebars templates in
    separate files that get loaded into JavaScript at buildtime.
  
    @property TEMPLATES
    @for Ember
    @type Object
    @private
  */
  Object.defineProperty(_emberMetal.default, 'TEMPLATES', {
    get: _emberGlimmer.getTemplates,
    set: _emberGlimmer.setTemplates,
    configurable: false,
    enumerable: false
  });

  exports.VERSION = _version.default;

  /**
    The semantic version
  
    @property VERSION
    @type String
    @public
  */
  _emberMetal.default.VERSION = _version.default;

  _emberMetal.libraries.registerCoreLibrary('Ember', _version.default);

  // require the main entry points for each of these packages
  // this is so that the global exports occur properly


  _emberMetal.default.$ = _emberViews.jQuery;

  _emberMetal.default.ViewTargetActionSupport = _emberViews.ViewTargetActionSupport;

  _emberMetal.default.ViewUtils = {
    isSimpleClick: _emberViews.isSimpleClick,
    getViewElement: _emberViews.getViewElement,
    getViewBounds: _emberViews.getViewBounds,
    getViewClientRects: _emberViews.getViewClientRects,
    getViewBoundingClientRect: _emberViews.getViewBoundingClientRect,
    getRootViews: _emberViews.getRootViews,
    getChildViews: _emberViews.getChildViews
  };

  _emberMetal.default.TextSupport = _emberViews.TextSupport;
  _emberMetal.default.ComponentLookup = _emberViews.ComponentLookup;
  _emberMetal.default.EventDispatcher = _emberViews.EventDispatcher;

  _emberMetal.default.Location = _emberRouting.Location;
  _emberMetal.default.AutoLocation = _emberRouting.AutoLocation;
  _emberMetal.default.HashLocation = _emberRouting.HashLocation;
  _emberMetal.default.HistoryLocation = _emberRouting.HistoryLocation;
  _emberMetal.default.NoneLocation = _emberRouting.NoneLocation;
  _emberMetal.default.controllerFor = _emberRouting.controllerFor;
  _emberMetal.default.generateControllerFactory = _emberRouting.generateControllerFactory;
  _emberMetal.default.generateController = _emberRouting.generateController;
  _emberMetal.default.RouterDSL = _emberRouting.RouterDSL;
  _emberMetal.default.Router = _emberRouting.Router;
  _emberMetal.default.Route = _emberRouting.Route;

  _emberMetal.default.Application = _emberApplication.Application;
  _emberMetal.default.ApplicationInstance = _emberApplication.ApplicationInstance;
  _emberMetal.default.Engine = _emberApplication.Engine;
  _emberMetal.default.EngineInstance = _emberApplication.EngineInstance;
  _emberMetal.default.DefaultResolver = _emberMetal.default.Resolver = _emberApplication.Resolver;

  (0, _emberRuntime.runLoadHooks)('Ember.Application', _emberApplication.Application);

  _emberMetal.default.DataAdapter = _emberExtensionSupport.DataAdapter;
  _emberMetal.default.ContainerDebugAdapter = _emberExtensionSupport.ContainerDebugAdapter;

  if ((0, _require2.has)('ember-template-compiler')) {
    (0, _require2.default)('ember-template-compiler');
  }

  // do this to ensure that Ember.Test is defined properly on the global
  // if it is present.
  if ((0, _require2.has)('ember-testing')) {
    testing = (0, _require2.default)('ember-testing');


    _emberMetal.default.Test = testing.Test;
    _emberMetal.default.Test.Adapter = testing.Adapter;
    _emberMetal.default.Test.QUnitAdapter = testing.QUnitAdapter;
    _emberMetal.default.setupForTesting = testing.setupForTesting;
  }

  (0, _emberRuntime.runLoadHooks)('Ember');

  /**
    @module ember
    @private
  */
  exports.default = _emberMetal.default;

  /* globals module */
  if (_nodeModule.IS_NODE) {
    _nodeModule.module.exports = _emberMetal.default;
  } else {
    _emberEnvironment.context.exports.Ember = _emberEnvironment.context.exports.Em = _emberMetal.default;
  }

  /**
   @module jquery
   @public
   */

  /**
   @class jquery
   @public
   @static
   */

  /**
    Alias for jQuery
  
    @for jquery
    @method $
    @static
    @public
  */
});