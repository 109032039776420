define("@embroider/macros/es-compat2", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = esCompat;
  function esCompat(m) {
    return m !== null && m !== void 0 && m.__esModule ? m : (0, _objectSpread2.default)({
      default: m
    }, m);
  }
});