define("semente-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    // Define your engine's route map here
    this.route('index', {
      path: '/'
    });
    this.route('landing', {
      path: '/landing/:pessoa_id'
    });
    this.route('modulos', function () {
      this.route('modlist', {
        path: '/modlist/:modulo_id'
      }, function () {});
      this.route('modlistdes', {
        path: '/modlistdes/:modulo_id'
      }, function () {});
      this.route('certificado', {
        path: '/certificado/:modulo_id'
      }, function () {});
      this.route('realizados', {
        path: '/realizados/:modulo_id'
      }, function () {});
      this.route('modetails', {
        path: '/modetails/:modulo_id'
      }, function () {
        this.route('ativdetails', {
          path: '/:atividade_id'
        }, function () {
          this.route('secdetails', {
            path: '/:secao_id'
          }, function () {});
          this.route('transitions', {
            path: '/transitions/:secao_id'
          }, function () {});
          this.route('relatorios', {
            path: '/relatorios/:secao_id'
          }, function () {});
        });
      });
      this.route('modetailsdes', {
        path: '/modetailsdes/:modulo_id'
      }, function () {
        this.route('ativdetailsdes', {
          path: '/:atividade_id'
        }, function () {
          this.route('secdetailsdes', {
            path: '/:secao_id'
          }, function () {});
          this.route('transitions', {
            path: '/transitions/:secao_id'
          }, function () {});
        });
      });
    });
    this.route('biblioteca', {
      path: '/biblioteca'
    });
    // this.route('maintenance', {
    //   path: '/'
    // });

    this.route('administracao', function () {
      this.route('admdata', function () {});
      this.route('persondetails', {
        path: '/persondetails/:pessoa_id'
      }, function () {});
    });
    this.route('relatorio-instituicoes', {
      path: '/relatorioinstituicao/:inst_id'
    }, function () {});
    this.route('autoper', {
      path: '/autopercepcoes/:person_id'
    }, function () {});
    this.route('gersistema', function () {
      this.route('gerdata', function () {});
    });
    this.route('uploader');
    // !for styleguide propouse only
    this.route('styleguide');
    // /for styleguide propouse only
  });
  _exports.default = _default;
});