enifed('ember-routing/services/routing', ['exports', 'ember-utils', 'ember-runtime', 'ember-metal', 'ember-routing/utils'], function (exports, _emberUtils, _emberRuntime, _emberMetal, _utils) {
  'use strict';

  exports.default = _emberRuntime.Service.extend({
    router: null,

    targetState: (0, _emberRuntime.readOnly)('router.targetState'),
    currentState: (0, _emberRuntime.readOnly)('router.currentState'),
    currentRouteName: (0, _emberRuntime.readOnly)('router.currentRouteName'),
    currentPath: (0, _emberRuntime.readOnly)('router.currentPath'),

    hasRoute: function (routeName) {
      return (0, _emberMetal.get)(this, 'router').hasRoute(routeName);
    },
    transitionTo: function (routeName, models, queryParams, shouldReplace) {
      var router = (0, _emberMetal.get)(this, 'router');

      var transition = router._doTransition(routeName, models, queryParams);

      if (shouldReplace) {
        transition.method('replace');
      }

      return transition;
    },
    normalizeQueryParams: function (routeName, models, queryParams) {
      var router = (0, _emberMetal.get)(this, 'router');
      router._prepareQueryParams(routeName, models, queryParams);
    },
    generateURL: function (routeName, models, queryParams) {
      var router = (0, _emberMetal.get)(this, 'router');
      if (!router._routerMicrolib) {
        return;
      }

      var visibleQueryParams = {};
      (0, _emberUtils.assign)(visibleQueryParams, queryParams);

      this.normalizeQueryParams(routeName, models, visibleQueryParams);

      var args = (0, _utils.routeArgs)(routeName, models, visibleQueryParams);
      return router.generate.apply(router, args);
    },
    isActiveForRoute: function (contexts, queryParams, routeName, routerState, isCurrentWhenSpecified) {
      var router = (0, _emberMetal.get)(this, 'router');

      var handlers = router._routerMicrolib.recognizer.handlersFor(routeName);
      var leafName = handlers[handlers.length - 1].handler;
      var maximumContexts = numberOfContextsAcceptedByHandler(routeName, handlers);

      // NOTE: any ugliness in the calculation of activeness is largely
      // due to the fact that we support automatic normalizing of
      // `resource` -> `resource.index`, even though there might be
      // dynamic segments / query params defined on `resource.index`
      // which complicates (and makes somewhat ambiguous) the calculation
      // of activeness for links that link to `resource` instead of
      // directly to `resource.index`.

      // if we don't have enough contexts revert back to full route name
      // this is because the leaf route will use one of the contexts
      if (contexts.length > maximumContexts) {
        routeName = leafName;
      }

      return routerState.isActiveIntent(routeName, contexts, queryParams, !isCurrentWhenSpecified);
    }
  });

  function numberOfContextsAcceptedByHandler(handler, handlerInfos) {
    var req = 0,
        i;
    for (i = 0; i < handlerInfos.length; i++) {
      req += handlerInfos[i].names.length;
      if (handlerInfos[i].handler === handler) {
        break;
      }
    }

    return req;
  }
});