define("@babel/runtime/helpers/esm/classPrivateMethodSet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _classPrivateMethodSet;
  function _classPrivateMethodSet() {
    throw new TypeError("attempted to reassign private method");
  }
});