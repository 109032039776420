define('ember-concurrency/-task-property', ['exports', 'ember-concurrency/-task-instance', 'ember-concurrency/-task-state-mixin', 'ember-concurrency/-task-group', 'ember-concurrency/-property-modifiers-mixin', 'ember-concurrency/utils', 'ember-concurrency/-encapsulated-task'], function (exports, _taskInstance, _taskStateMixin, _taskGroup, _propertyModifiersMixin, _utils, _encapsulatedTask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TaskProperty = exports.Task = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  var _get = function get(object, property, receiver) {
    if (object === null) object = Function.prototype;
    var desc = Object.getOwnPropertyDescriptor(object, property);

    if (desc === undefined) {
      var parent = Object.getPrototypeOf(object);

      if (parent === null) {
        return undefined;
      } else {
        return get(parent, property, receiver);
      }
    } else if ("value" in desc) {
      return desc.value;
    } else {
      var getter = desc.get;

      if (getter === undefined) {
        return undefined;
      }

      return getter.call(receiver);
    }
  };

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var PerformProxy = Ember.Object.extend({
    _task: null,
    _performType: null,
    _linkedObject: null,

    perform: function perform() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return this._task._performShared(args, this._performType, this._linkedObject);
    }
  });

  /**
    The `Task` object lives on a host Ember object (e.g.
    a Component, Route, or Controller). You call the
    {@linkcode Task#perform .perform()} method on this object
    to create run individual {@linkcode TaskInstance}s,
    and at any point, you can call the {@linkcode Task#cancelAll .cancelAll()}
    method on this object to cancel all running or enqueued
    {@linkcode TaskInstance}s.
  
  
    <style>
      .ignore-this--this-is-here-to-hide-constructor,
      #Task{ display: none }
    </style>
  
    @class Task
  */
  var Task = exports.Task = Ember.Object.extend(_taskStateMixin.default, _defineProperty({
    /**
     * `true` if any current task instances are running.
     *
     * @memberof Task
     * @member {boolean} isRunning
     * @instance
     * @readOnly
     */

    /**
     * `true` if any future task instances are queued.
     *
     * @memberof Task
     * @member {boolean} isQueued
     * @instance
     * @readOnly
     */

    /**
     * `true` if the task is not in the running or queued state.
     *
     * @memberof Task
     * @member {boolean} isIdle
     * @instance
     * @readOnly
     */

    /**
     * The current state of the task: `"running"`, `"queued"` or `"idle"`.
     *
     * @memberof Task
     * @member {string} state
     * @instance
     * @readOnly
     */

    /**
     * The most recently started task instance.
     *
     * @memberof Task
     * @member {TaskInstance} last
     * @instance
     * @readOnly
     */

    /**
     * The most recent task instance that is currently running.
     *
     * @memberof Task
     * @member {TaskInstance} lastRunning
     * @instance
     * @readOnly
     */

    /**
     * The most recently performed task instance.
     *
     * @memberof Task
     * @member {TaskInstance} lastPerformed
     * @instance
     * @readOnly
     */

    /**
     * The most recent task instance that succeeded.
     *
     * @memberof Task
     * @member {TaskInstance} lastSuccessful
     * @instance
     * @readOnly
     */

    /**
     * The most recently completed task instance.
     *
     * @memberof Task
     * @member {TaskInstance} lastComplete
     * @instance
     * @readOnly
     */

    /**
     * The most recent task instance that errored.
     *
     * @memberof Task
     * @member {TaskInstance} lastErrored
     * @instance
     * @readOnly
     */

    /**
     * The most recently canceled task instance.
     *
     * @memberof Task
     * @member {TaskInstance} lastCanceled
     * @instance
     * @readOnly
     */

    /**
     * The most recent task instance that is incomplete.
     *
     * @memberof Task
     * @member {TaskInstance} lastIncomplete
     * @instance
     * @readOnly
     */

    /**
     * The number of times this task has been performed.
     *
     * @memberof Task
     * @member {number} performCount
     * @instance
     * @readOnly
     */

    fn: null,
    context: null,
    _observes: null,
    _curryArgs: null,
    _linkedObjects: null,

    init: function init() {
      this._super.apply(this, arguments);

      if (_typeof(this.fn) === 'object') {
        var owner = Ember.getOwner(this.context);
        var ownerInjection = owner ? owner.ownerInjection() : {};
        this._taskInstanceFactory = _encapsulatedTask.default.extend(ownerInjection, this.fn);
      }

      (0, _utils._cleanupOnDestroy)(this.context, this, 'cancelAll', { reason: 'the object it lives on was destroyed or unrendered' });
    },
    _curry: function _curry() {
      var task = this._clone();

      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      task._curryArgs = [].concat(_toConsumableArray(this._curryArgs || []), _toConsumableArray(args));
      return task;
    },
    linked: function linked() {
      var taskInstance = (0, _taskInstance.getRunningInstance)();
      if (!taskInstance) {
        throw new Error('You can only call .linked() from within a task.');
      }

      return PerformProxy.create({
        _task: this,
        _performType: _taskInstance.PERFORM_TYPE_LINKED,
        _linkedObject: taskInstance
      });
    },
    unlinked: function unlinked() {
      return PerformProxy.create({
        _task: this,
        _performType: _taskInstance.PERFORM_TYPE_UNLINKED
      });
    },
    _clone: function _clone() {
      return Task.create({
        fn: this.fn,
        context: this.context,
        _origin: this._origin,
        _taskGroupPath: this._taskGroupPath,
        _scheduler: this._scheduler,
        _propertyName: this._propertyName
      });
    },


    /**
     * This property is true if this task is NOT running, i.e. the number
     * of currently running TaskInstances is zero.
     *
     * This property is useful for driving the state/style of buttons
     * and loading UI, among other things.
     *
     * @memberof Task
     * @instance
     * @readOnly
     */

    /**
     * This property is true if this task is running, i.e. the number
     * of currently running TaskInstances is greater than zero.
     *
     * This property is useful for driving the state/style of buttons
     * and loading UI, among other things.
     *
     * @memberof Task
     * @instance
     * @readOnly
     */

    /**
     * EXPERIMENTAL
     *
     * This value describes what would happen to the TaskInstance returned
     * from .perform() if .perform() were called right now.  Returns one of
     * the following values:
     *
     * - `succeed`: new TaskInstance will start running immediately
     * - `drop`: new TaskInstance will be dropped
     * - `enqueue`: new TaskInstance will be enqueued for later execution
     *
     * @memberof Task
     * @instance
     * @private
     * @readOnly
     */

    /**
     * EXPERIMENTAL
     *
     * Returns true if calling .perform() right now would immediately start running
     * the returned TaskInstance.
     *
     * @memberof Task
     * @instance
     * @private
     * @readOnly
     */

    /**
     * EXPERIMENTAL
     *
     * Returns true if calling .perform() right now would immediately cancel (drop)
     * the returned TaskInstance.
     *
     * @memberof Task
     * @instance
     * @private
     * @readOnly
     */

    /**
     * EXPERIMENTAL
     *
     * Returns true if calling .perform() right now would enqueue the TaskInstance
     * rather than execute immediately.
     *
     * @memberof Task
     * @instance
     * @private
     * @readOnly
     */

    /**
     * EXPERIMENTAL
     *
     * Returns true if calling .perform() right now would cause a previous task to be canceled
     *
     * @memberof Task
     * @instance
     * @private
     * @readOnly
     */

    /**
     * The current number of active running task instances. This
     * number will never exceed maxConcurrency.
     *
     * @memberof Task
     * @instance
     * @readOnly
     */

    /**
     * Cancels all running or queued `TaskInstance`s for this Task.
     * If you're trying to cancel a specific TaskInstance (rather
     * than all of the instances running under this task) call
     * `.cancel()` on the specific TaskInstance.
     *
     * @method cancelAll
     * @memberof Task
     * @instance
     */

    toString: function toString() {
      return '<Task:' + this._propertyName + '>';
    },


    _taskInstanceFactory: _taskInstance.default,

    /**
     * Creates a new {@linkcode TaskInstance} and attempts to run it right away.
     * If running this task instance would increase the task's concurrency
     * to a number greater than the task's maxConcurrency, this task
     * instance might be immediately canceled (dropped), or enqueued
     * to run at later time, after the currently running task(s) have finished.
     *
     * @method perform
     * @memberof Task
     * @param {*} arg* - args to pass to the task function
     * @instance
     *
     * @fires TaskInstance#TASK_NAME:started
     * @fires TaskInstance#TASK_NAME:succeeded
     * @fires TaskInstance#TASK_NAME:errored
     * @fires TaskInstance#TASK_NAME:canceled
     *
     */
    perform: function perform() {
      for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      return this._performShared(args, _taskInstance.PERFORM_TYPE_DEFAULT, null);
    },
    _performShared: function _performShared(args, performType, linkedObject) {
      var fullArgs = this._curryArgs ? [].concat(_toConsumableArray(this._curryArgs), _toConsumableArray(args)) : args;
      var taskInstance = this._taskInstanceFactory.create({
        fn: this.fn,
        args: fullArgs,
        context: this.context,
        owner: this.context,
        task: this,
        _debug: this._debug,
        _hasEnabledEvents: this._hasEnabledEvents,
        _origin: this,
        _performType: performType
      });

      if (performType === _taskInstance.PERFORM_TYPE_LINKED) {
        linkedObject._expectsLinkedYield = true;
      }

      if (this.context.isDestroying) {
        // TODO: express this in terms of lifetimes; a task linked to
        // a dead lifetime should immediately cancel.
        taskInstance.cancel();
      }

      this._scheduler.schedule(taskInstance);
      return taskInstance;
    }
  }, _utils.INVOKE, function () {
    return this.perform.apply(this, arguments);
  }));

  /**
    A {@link TaskProperty} is the Computed Property-like object returned
    from the {@linkcode task} function. You can call Task Modifier methods
    on this object to configure the behavior of the {@link Task}.
  
    See [Managing Task Concurrency](/#/docs/task-concurrency) for an
    overview of all the different task modifiers you can use and how
    they impact automatic cancelation / enqueueing of task instances.
  
    <style>
      .ignore-this--this-is-here-to-hide-constructor,
      #TaskProperty { display: none }
    </style>
  
    @class TaskProperty
  */

  var TaskProperty = exports.TaskProperty = function (_ComputedProperty2) {
    _inherits(TaskProperty, _ComputedProperty2);

    function TaskProperty(taskFn) {
      _classCallCheck(this, TaskProperty);

      var tp = void 0;

      var _this = _possibleConstructorReturn(this, (TaskProperty.__proto__ || Object.getPrototypeOf(TaskProperty)).call(this, function (_propertyName) {
        taskFn.displayName = _propertyName + ' (task)';
        return Task.create({
          fn: tp.taskFn,
          context: this,
          _origin: this,
          _taskGroupPath: tp._taskGroupPath,
          _scheduler: (0, _propertyModifiersMixin.resolveScheduler)(tp, this, _taskGroup.TaskGroup),
          _propertyName: _propertyName,
          _debug: tp._debug,
          _hasEnabledEvents: tp._hasEnabledEvents
        });
      }));

      tp = _this;
      _this.taskFn = taskFn;
      _this.eventNames = null;
      _this.cancelEventNames = null;
      _this._observes = null;
      return _this;
    }

    _createClass(TaskProperty, [{
      key: 'setup',
      value: function setup(proto, taskName) {
        if (_get(TaskProperty.prototype.__proto__ || Object.getPrototypeOf(TaskProperty.prototype), 'setup', this)) {
          _get(TaskProperty.prototype.__proto__ || Object.getPrototypeOf(TaskProperty.prototype), 'setup', this).apply(this, arguments);
        }
        if (this._maxConcurrency !== Infinity && !this._hasSetBufferPolicy) {
          // eslint-disable-next-line no-console
          console.warn('The use of maxConcurrency() without a specified task modifier is deprecated and won\'t be supported in future versions of ember-concurrency. Please specify a task modifier instead, e.g. `' + taskName + ': task(...).enqueue().maxConcurrency(' + this._maxConcurrency + ')`');
        }

        registerOnPrototype(Ember.addListener, proto, this.eventNames, taskName, 'perform', false);
        registerOnPrototype(Ember.addListener, proto, this.cancelEventNames, taskName, 'cancelAll', false);
        registerOnPrototype(Ember.addObserver, proto, this._observes, taskName, 'perform', true);
      }

      /**
       * Calling `task(...).on(eventName)` configures the task to be
       * automatically performed when the specified events fire. In
       * this way, it behaves like
       * [Ember.on](http://emberjs.com/api/classes/Ember.html#method_on).
       *
       * You can use `task(...).on('init')` to perform the task
       * when the host object is initialized.
       *
       * ```js
       * export default Ember.Component.extend({
       *   pollForUpdates: task(function * () {
       *     // ... this runs when the Component is first created
       *     // because we specified .on('init')
       *   }).on('init'),
       *
       *   handleFoo: task(function * (a, b, c) {
       *     // this gets performed automatically if the 'foo'
       *     // event fires on this Component,
       *     // e.g., if someone called component.trigger('foo')
       *   }).on('foo'),
       * });
       * ```
       *
       * [See the Writing Tasks Docs for more info](/#/docs/writing-tasks)
       *
       * @method on
       * @memberof TaskProperty
       * @param {String} eventNames*
       * @instance
       */

    }, {
      key: 'on',
      value: function on() {
        this.eventNames = this.eventNames || [];
        this.eventNames.push.apply(this.eventNames, arguments);
        return this;
      }

      /**
       * This behaves like the {@linkcode TaskProperty#on task(...).on() modifier},
       * but instead will cause the task to be canceled if any of the
       * specified events fire on the parent object.
       *
       * [See the Live Example](/#/docs/examples/route-tasks/1)
       *
       * @method cancelOn
       * @memberof TaskProperty
       * @param {String} eventNames*
       * @instance
       */

    }, {
      key: 'cancelOn',
      value: function cancelOn() {
        this.cancelEventNames = this.cancelEventNames || [];
        this.cancelEventNames.push.apply(this.cancelEventNames, arguments);
        return this;
      }
    }, {
      key: 'observes',
      value: function observes() {
        for (var _len4 = arguments.length, properties = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
          properties[_key4] = arguments[_key4];
        }

        this._observes = properties;
        return this;
      }

      /**
       * Configures the task to cancel old currently task instances
       * to make room for a new one to perform. Sets default
       * maxConcurrency to 1.
       *
       * [See the Live Example](/#/docs/examples/route-tasks/1)
       *
       * @method restartable
       * @memberof TaskProperty
       * @instance
       */

      /**
       * Configures the task to run task instances one-at-a-time in
       * the order they were `.perform()`ed. Sets default
       * maxConcurrency to 1.
       *
       * @method enqueue
       * @memberof TaskProperty
       * @instance
       */

      /**
       * Configures the task to immediately cancel (i.e. drop) any
       * task instances performed when the task is already running
       * at maxConcurrency. Sets default maxConcurrency to 1.
       *
       * @method drop
       * @memberof TaskProperty
       * @instance
       */

      /**
       * Configures the task to drop all but the most recently
       * performed {@linkcode TaskInstance }.
       *
       * @method keepLatest
       * @memberof TaskProperty
       * @instance
       */

      /**
       * Sets the maximum number of task instances that are allowed
       * to run at the same time. By default, with no task modifiers
       * applied, this number is Infinity (there is no limit
       * to the number of tasks that can run at the same time).
       * {@linkcode TaskProperty#restartable .restartable()},
       * {@linkcode TaskProperty#enqueue .enqueue()}, and
       * {@linkcode TaskProperty#drop .drop()} set the default
       * maxConcurrency to 1, but you can override this value
       * to set the maximum number of concurrently running tasks
       * to a number greater than 1.
       *
       * [See the AJAX Throttling example](/#/docs/examples/ajax-throttling)
       *
       * The example below uses a task with `maxConcurrency(3)` to limit
       * the number of concurrent AJAX requests (for anyone using this task)
       * to 3.
       *
       * ```js
       * doSomeAjax: task(function * (url) {
       *   return Ember.$.getJSON(url).promise();
       * }).maxConcurrency(3),
       *
       * elsewhere() {
       *   this.get('doSomeAjax').perform("http://www.example.com/json");
       * },
       * ```
       *
       * @method maxConcurrency
       * @memberof TaskProperty
       * @param {Number} n The maximum number of concurrently running tasks
       * @instance
       */

      /**
       * Adds this task to a TaskGroup so that concurrency constraints
       * can be shared between multiple tasks.
       *
       * [See the Task Group docs for more information](/#/docs/task-groups)
       *
       * @method group
       * @memberof TaskProperty
       * @param {String} groupPath A path to the TaskGroup property
       * @instance
       */

      /**
       * Activates lifecycle events, allowing Evented host objects to react to task state
       * changes.
       *
       * ```js
       *
       * export default Component.extend({
       *   uploadTask: task(function* (file) {
       *     // ... file upload stuff
       *   }).evented(),
       *
       *   uploadedStarted: on('uploadTask:started', function(taskInstance) {
       *     this.get('analytics').track("User Photo: upload started");
       *   }),
       * });
       * ```
       *
       * @method evented
       * @memberof TaskProperty
       * @instance
       */

      /**
       * Logs lifecycle events to aid in debugging unexpected Task behavior.
       * Presently only logs cancelation events and the reason for the cancelation,
       * e.g. "TaskInstance 'doStuff' was canceled because the object it lives on was destroyed or unrendered"
       *
       * @method debug
       * @memberof TaskProperty
       * @instance
       */

    }, {
      key: 'perform',
      value: function perform() {
        (false && !(false) && Ember.deprecate('[DEPRECATED] An ember-concurrency task property was not set on its object via \'defineProperty\'. \n              You probably used \'set(obj, "myTask", task(function* () { ... }) )\'. \n              Unfortunately due to this we can\'t tell you the name of the task.', false, {
          id: 'ember-meta.descriptor-on-object',
          until: '3.5.0',
          url: 'https://emberjs.com/deprecations/v3.x#toc_use-defineProperty-to-define-computed-properties'
        }));

        throw new Error("An ember-concurrency task property was not set on its object via 'defineProperty'. See deprecation warning for details.");
      }
    }]);

    return TaskProperty;
  }(_utils._ComputedProperty);

  (0, _utils.objectAssign)(TaskProperty.prototype, _propertyModifiersMixin.propertyModifiers);

  var handlerCounter = 0;

  function registerOnPrototype(addListenerOrObserver, proto, names, taskName, taskMethod, once) {
    if (names) {
      for (var i = 0; i < names.length; ++i) {
        var name = names[i];

        var handlerName = '__ember_concurrency_handler_' + handlerCounter++;
        proto[handlerName] = makeTaskCallback(taskName, taskMethod, once);
        addListenerOrObserver(proto, name, null, handlerName);
      }
    }
  }

  function makeTaskCallback(taskName, method, once) {
    return function () {
      var task = this.get(taskName);

      if (once) {
        Ember.run.scheduleOnce.apply(undefined, ['actions', task, method].concat(Array.prototype.slice.call(arguments)));
      } else {
        task[method].apply(task, arguments);
      }
    };
  }
});