define("@babel/runtime/helpers/esm/awaitAsyncGenerator", ["exports", "@babel/runtime/helpers/esm/AwaitValue"], function (_exports, _AwaitValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _awaitAsyncGenerator;
  function _awaitAsyncGenerator(value) {
    return new _AwaitValue.default(value);
  }
});