enifed('ember-glimmer/component-managers/curly', ['exports', 'ember-babel', 'ember-utils', '@glimmer/reference', '@glimmer/runtime', 'ember-debug', 'ember-glimmer/component', 'ember-glimmer/utils/bindings', 'ember-metal', 'ember-glimmer/utils/process-args', 'ember-views', 'container', 'ember-glimmer/component-managers/abstract', 'ember-glimmer/utils/curly-component-state-bucket', 'ember-glimmer/utils/references'], function (exports, _emberBabel, _emberUtils, _reference, _runtime, _emberDebug, _component, _bindings, _emberMetal, _processArgs, _emberViews, _container, _abstract, _curlyComponentStateBucket, _references) {
  'use strict';

  exports.CurlyComponentDefinition = exports.PositionalArgumentReference = undefined;
  exports.validatePositionalParameters = function () {};
  exports.processComponentInitializationAssertions = function (component, props) {
    false && !function () {
      var classNameBindings = component.classNameBindings,
          i,
          binding;

      for (i = 0; i < classNameBindings.length; i++) {
        binding = classNameBindings[i];

        if (binding.split(' ').length > 1) {
          return false;
        }
      }
      return true;
    }() && (0, _emberDebug.assert)('classNameBindings must not have spaces in them: ' + component.toString(), function () {
      var classNameBindings = component.classNameBindings,
          i,
          binding;
      for (i = 0; i < classNameBindings.length; i++) {
        binding = classNameBindings[i];
        if (binding.split(' ').length > 1) {
          return false;
        }
      }return true;
    }());
    false && !function () {
      var classNameBindings = component.classNameBindings,
          tagName = component.tagName;

      return tagName !== '' || !classNameBindings || classNameBindings.length === 0;
    }() && (0, _emberDebug.assert)('You cannot use `classNameBindings` on a tag-less component: ' + component.toString(), function () {
      var classNameBindings = component.classNameBindings,
          tagName = component.tagName;
      return tagName !== '' || !classNameBindings || classNameBindings.length === 0;
    }());
    false && !function () {
      var elementId = component.elementId,
          tagName = component.tagName;

      return tagName !== '' || props.id === elementId || !elementId && elementId !== '';
    }() && (0, _emberDebug.assert)('You cannot use `elementId` on a tag-less component: ' + component.toString(), function () {
      var elementId = component.elementId,
          tagName = component.tagName;
      return tagName !== '' || props.id === elementId || !elementId && elementId !== '';
    }());
    false && !function () {
      var attributeBindings = component.attributeBindings,
          tagName = component.tagName;

      return tagName !== '' || !attributeBindings || attributeBindings.length === 0;
    }() && (0, _emberDebug.assert)('You cannot use `attributeBindings` on a tag-less component: ' + component.toString(), function () {
      var attributeBindings = component.attributeBindings,
          tagName = component.tagName;
      return tagName !== '' || !attributeBindings || attributeBindings.length === 0;
    }());
  };
  exports.initialRenderInstrumentDetails = initialRenderInstrumentDetails;
  exports.rerenderInstrumentDetails = rerenderInstrumentDetails;

  var _templateObject = (0, _emberBabel.taggedTemplateLiteralLoose)(['template:components/-default'], ['template:components/-default']);

  var DEFAULT_LAYOUT = (0, _container.privatize)(_templateObject);

  function aliasIdToElementId(args, props) {
    if (args.named.has('id')) {
      false && !!args.named.has('elementId') && (0, _emberDebug.assert)('You cannot invoke a component with both \'id\' and \'elementId\' at the same time.', !args.named.has('elementId'));

      props.elementId = props.id;
    }
  }

  // We must traverse the attributeBindings in reverse keeping track of
  // what has already been applied. This is essentially refining the concated
  // properties applying right to left.
  function applyAttributeBindings(element, attributeBindings, component, operations) {
    var seen = [],
        binding,
        parsed,
        attribute;
    var i = attributeBindings.length - 1;

    while (i !== -1) {
      binding = attributeBindings[i];
      parsed = _bindings.AttributeBinding.parse(binding);
      attribute = parsed[1];


      if (seen.indexOf(attribute) === -1) {
        seen.push(attribute);
        _bindings.AttributeBinding.install(element, component, parsed, operations);
      }

      i--;
    }

    if (seen.indexOf('id') === -1) {
      operations.addStaticAttribute(element, 'id', component.elementId);
    }

    if (seen.indexOf('style') === -1) {
      _bindings.IsVisibleBinding.install(element, component, operations);
    }
  }

  function tagName(vm) {
    var tagName = vm.dynamicScope().view.tagName;

    return _runtime.PrimitiveReference.create(tagName === '' ? null : tagName || 'div');
  }

  function ariaRole(vm) {
    return vm.getSelf().get('ariaRole');
  }

  var CurlyComponentLayoutCompiler = function () {
    function CurlyComponentLayoutCompiler(template) {

      this.template = template;
    }

    CurlyComponentLayoutCompiler.prototype.compile = function (builder) {
      builder.wrapLayout(this.template);
      builder.tag.dynamic(tagName);
      builder.attrs.dynamic('role', ariaRole);
      builder.attrs.static('class', 'ember-view');
    };

    return CurlyComponentLayoutCompiler;
  }();

  CurlyComponentLayoutCompiler.id = 'curly';

  var PositionalArgumentReference = exports.PositionalArgumentReference = function () {
    function PositionalArgumentReference(references) {

      this.tag = (0, _reference.combineTagged)(references);
      this._references = references;
    }

    PositionalArgumentReference.prototype.value = function () {
      return this._references.map(function (reference) {
        return reference.value();
      });
    };

    PositionalArgumentReference.prototype.get = function (key) {
      return _references.PropertyReference.create(this, key);
    };

    return PositionalArgumentReference;
  }();

  var CurlyComponentManager = function (_AbstractManager) {
    (0, _emberBabel.inherits)(CurlyComponentManager, _AbstractManager);

    function CurlyComponentManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
    }

    CurlyComponentManager.prototype.prepareArgs = function (definition, args) {
      var componentPositionalParamsDefinition = definition.ComponentClass.class.positionalParams,
          remainingDefinitionPositionals,
          _positionalParamsToNa,
          length,
          i,
          name;

      var componentHasRestStylePositionalParams = typeof componentPositionalParamsDefinition === 'string';
      var componentHasPositionalParams = componentHasRestStylePositionalParams || componentPositionalParamsDefinition.length > 0;
      var needsPositionalParamMunging = componentHasPositionalParams && args.positional.length !== 0;
      var isClosureComponent = definition.args;

      if (!needsPositionalParamMunging && !isClosureComponent) {
        return null;
      }

      var capturedArgs = args.capture();
      // grab raw positional references array
      var positional = capturedArgs.positional.references;

      // handle prep for closure component with positional params
      var curriedNamed = void 0;
      if (definition.args) {
        remainingDefinitionPositionals = definition.args.positional.slice(positional.length);

        positional = positional.concat(remainingDefinitionPositionals);
        curriedNamed = definition.args.named;
      }

      // handle positionalParams
      var positionalParamsToNamed = void 0;
      if (componentHasRestStylePositionalParams) {

        positionalParamsToNamed = (_positionalParamsToNa = {}, _positionalParamsToNa[componentPositionalParamsDefinition] = new PositionalArgumentReference(positional), _positionalParamsToNa);
        positional = [];
      } else if (componentHasPositionalParams) {
        positionalParamsToNamed = {};
        length = Math.min(positional.length, componentPositionalParamsDefinition.length);

        for (i = 0; i < length; i++) {
          name = componentPositionalParamsDefinition[i];

          positionalParamsToNamed[name] = positional[i];
        }
      }

      var named = (0, _emberUtils.assign)({}, curriedNamed, positionalParamsToNamed, capturedArgs.named.map);

      return { positional: positional, named: named };
    };

    CurlyComponentManager.prototype.create = function (environment, definition, args, dynamicScope, callerSelfRef, hasBlock) {

      var parentView = dynamicScope.view;

      var factory = definition.ComponentClass;

      var capturedArgs = args.named.capture();
      var props = (0, _processArgs.processComponentArgs)(capturedArgs);

      aliasIdToElementId(args, props);

      props.parentView = parentView;
      props[_component.HAS_BLOCK] = hasBlock;

      props._targetObject = callerSelfRef.value();

      var component = factory.create(props);

      var finalizer = (0, _emberMetal._instrumentStart)('render.component', initialRenderInstrumentDetails, component);

      dynamicScope.view = component;

      if (parentView !== null) {
        parentView.appendChild(component);
      }

      // We usually do this in the `didCreateElement`, but that hook doesn't fire for tagless components
      if (component.tagName === '') {
        if (environment.isInteractive) {
          component.trigger('willRender');
        }

        component._transitionTo('hasElement');

        if (environment.isInteractive) {
          component.trigger('willInsertElement');
        }
      }

      var bucket = new _curlyComponentStateBucket.default(environment, component, capturedArgs, finalizer);

      if (args.named.has('class')) {
        bucket.classRef = args.named.get('class');
      }

      if (environment.isInteractive && component.tagName !== '') {
        component.trigger('willRender');
      }

      return bucket;
    };

    CurlyComponentManager.prototype.layoutFor = function (definition, bucket, env) {
      var template = definition.template,
          component;
      if (!template) {
        component = bucket.component;


        template = this.templateFor(component, env);
      }
      return env.getCompiledBlock(CurlyComponentLayoutCompiler, template);
    };

    CurlyComponentManager.prototype.templateFor = function (component, env) {
      var Template = (0, _emberMetal.get)(component, 'layout'),
          template;
      var owner = component[_emberUtils.OWNER];
      if (Template) {
        return env.getTemplate(Template, owner);
      }
      var layoutName = (0, _emberMetal.get)(component, 'layoutName');
      if (layoutName) {
        template = owner.lookup('template:' + layoutName);

        if (template) {
          return template;
        }
      }
      return owner.lookup(DEFAULT_LAYOUT);
    };

    CurlyComponentManager.prototype.getSelf = function (_ref) {
      var component = _ref.component;

      return component[_component.ROOT_REF];
    };

    CurlyComponentManager.prototype.didCreateElement = function (_ref2, element, operations) {
      var component = _ref2.component,
          classRef = _ref2.classRef,
          environment = _ref2.environment;

      (0, _emberViews.setViewElement)(component, element);

      var attributeBindings = component.attributeBindings,
          classNames = component.classNames,
          classNameBindings = component.classNameBindings;

      if (attributeBindings && attributeBindings.length) {
        applyAttributeBindings(element, attributeBindings, component, operations);
      } else {
        operations.addStaticAttribute(element, 'id', component.elementId);
        _bindings.IsVisibleBinding.install(element, component, operations);
      }

      if (classRef) {
        operations.addDynamicAttribute(element, 'class', classRef);
      }

      if (classNames && classNames.length) {
        classNames.forEach(function (name) {
          operations.addStaticAttribute(element, 'class', name);
        });
      }

      if (classNameBindings && classNameBindings.length) {
        classNameBindings.forEach(function (binding) {
          _bindings.ClassNameBinding.install(element, component, binding, operations);
        });
      }

      component._transitionTo('hasElement');

      if (environment.isInteractive) {
        component.trigger('willInsertElement');
      }
    };

    CurlyComponentManager.prototype.didRenderLayout = function (bucket, bounds) {
      bucket.component[_component.BOUNDS] = bounds;
      bucket.finalize();
    };

    CurlyComponentManager.prototype.getTag = function (_ref3) {
      var component = _ref3.component;

      return component[_component.DIRTY_TAG];
    };

    CurlyComponentManager.prototype.didCreate = function (_ref4) {
      var component = _ref4.component,
          environment = _ref4.environment;

      if (environment.isInteractive) {
        component._transitionTo('inDOM');
        component.trigger('didInsertElement');
        component.trigger('didRender');
      }
    };

    CurlyComponentManager.prototype.update = function (bucket) {
      var component = bucket.component,
          args = bucket.args,
          argsRevision = bucket.argsRevision,
          environment = bucket.environment,
          props;

      bucket.finalizer = (0, _emberMetal._instrumentStart)('render.component', rerenderInstrumentDetails, component);

      if (!args.tag.validate(argsRevision)) {
        props = (0, _processArgs.processComponentArgs)(args);


        bucket.argsRevision = args.tag.value();

        component[_component.IS_DISPATCHING_ATTRS] = true;
        component.setProperties(props);
        component[_component.IS_DISPATCHING_ATTRS] = false;

        component.trigger('didUpdateAttrs');
        component.trigger('didReceiveAttrs');
      }

      if (environment.isInteractive) {
        component.trigger('willUpdate');
        component.trigger('willRender');
      }
    };

    CurlyComponentManager.prototype.didUpdateLayout = function (bucket) {
      bucket.finalize();
    };

    CurlyComponentManager.prototype.didUpdate = function (_ref5) {
      var component = _ref5.component,
          environment = _ref5.environment;

      if (environment.isInteractive) {
        component.trigger('didUpdate');
        component.trigger('didRender');
      }
    };

    CurlyComponentManager.prototype.getDestructor = function (stateBucket) {
      return stateBucket;
    };

    return CurlyComponentManager;
  }(_abstract.default);

  exports.default = CurlyComponentManager;


  function initialRenderInstrumentDetails(component) {
    return component.instrumentDetails({ initialRender: true });
  }

  function rerenderInstrumentDetails(component) {
    return component.instrumentDetails({ initialRender: false });
  }

  var MANAGER = new CurlyComponentManager();

  exports.CurlyComponentDefinition = function (_ComponentDefinition) {
    (0, _emberBabel.inherits)(CurlyComponentDefinition, _ComponentDefinition);

    function CurlyComponentDefinition(name, ComponentClass, template, args, customManager) {

      var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, name, customManager || MANAGER, ComponentClass));

      _this2.template = template;
      _this2.args = args;
      return _this2;
    }

    return CurlyComponentDefinition;
  }(_runtime.ComponentDefinition);
});