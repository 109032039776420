enifed('ember-runtime/mixins/mutable_enumerable', ['exports', 'ember-runtime/mixins/enumerable', 'ember-metal'], function (exports, _enumerable, _emberMetal) {
  'use strict';

  exports.default = _emberMetal.Mixin.create(_enumerable.default, {

    /**
      __Required.__ You must implement this method to apply this mixin.
       Attempts to add the passed object to the receiver if the object is not
      already present in the collection. If the object is present, this method
      has no effect.
       If the passed object is of a type not supported by the receiver,
      then this method should raise an exception.
       @method addObject
      @param {Object} object The object to add to the enumerable.
      @return {Object} the passed object
      @public
    */
    addObject: null,

    /**
      Adds each object in the passed enumerable to the receiver.
       @method addObjects
      @param {Ember.Enumerable} objects the objects to add.
      @return {Object} receiver
      @public
    */
    addObjects: function (objects) {
      var _this = this;

      (0, _emberMetal.beginPropertyChanges)(this);
      objects.forEach(function (obj) {
        return _this.addObject(obj);
      });
      (0, _emberMetal.endPropertyChanges)(this);
      return this;
    },

    /**
      __Required.__ You must implement this method to apply this mixin.
       Attempts to remove the passed object from the receiver collection if the
      object is present in the collection. If the object is not present,
      this method has no effect.
       If the passed object is of a type not supported by the receiver,
      then this method should raise an exception.
       @method removeObject
      @param {Object} object The object to remove from the enumerable.
      @return {Object} the passed object
      @public
    */
    removeObject: null,

    /**
      Removes each object in the passed enumerable from the receiver.
       @method removeObjects
      @param {Ember.Enumerable} objects the objects to remove
      @return {Object} receiver
      @public
    */
    removeObjects: function (objects) {
      var i;

      (0, _emberMetal.beginPropertyChanges)(this);
      for (i = objects.length - 1; i >= 0; i--) {
        this.removeObject(objects[i]);
      }
      (0, _emberMetal.endPropertyChanges)(this);
      return this;
    }
  });
});