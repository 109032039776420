enifed('@glimmer/util', ['exports'], function (exports) {
    'use strict';

    // There is a small whitelist of namespaced attributes specially
    // enumerated in
    // https://www.w3.org/TR/html/syntax.html#attributes-0
    //
    // > When a foreign element has one of the namespaced attributes given by
    // > the local name and namespace of the first and second cells of a row
    // > from the following table, it must be written using the name given by
    // > the third cell from the same row.
    //
    // In all other cases, colons are interpreted as a regular character
    // with no special meaning:
    //
    // > No other namespaced attribute can be expressed in the HTML syntax.

    var XLINK = 'http://www.w3.org/1999/xlink';
    var XML = 'http://www.w3.org/XML/1998/namespace';
    var XMLNS = 'http://www.w3.org/2000/xmlns/';
    var WHITELIST = {
        'xlink:actuate': XLINK,
        'xlink:arcrole': XLINK,
        'xlink:href': XLINK,
        'xlink:role': XLINK,
        'xlink:show': XLINK,
        'xlink:title': XLINK,
        'xlink:type': XLINK,
        'xml:base': XML,
        'xml:lang': XML,
        'xml:space': XML,
        'xmlns': XMLNS,
        'xmlns:xlink': XMLNS
    };

    // import Logger from './logger';
    // let alreadyWarned = false;
    // import Logger from './logger';


    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var LogLevel;
    (function (LogLevel) {
        LogLevel[LogLevel["Trace"] = 0] = "Trace";
        LogLevel[LogLevel["Debug"] = 1] = "Debug";
        LogLevel[LogLevel["Warn"] = 2] = "Warn";
        LogLevel[LogLevel["Error"] = 3] = "Error";
    })(LogLevel || (exports.LogLevel = LogLevel = {}));

    var NullConsole = function () {
        function NullConsole() {
            _classCallCheck(this, NullConsole);
        }

        NullConsole.prototype.log = function () {};

        NullConsole.prototype.warn = function () {};

        NullConsole.prototype.error = function () {};

        NullConsole.prototype.trace = function () {};

        return NullConsole;
    }();

    var ALWAYS = void 0;
    var Logger = function () {
        function Logger(_ref) {
            var console = _ref.console,
                level = _ref.level;

            _classCallCheck(this, Logger);

            this.f = ALWAYS;
            this.force = ALWAYS;
            this.console = console;
            this.level = level;
        }

        Logger.prototype.skipped = function (level) {
            return level < this.level;
        };

        Logger.prototype.trace = function (message) {
            var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
                _ref2$stackTrace = _ref2.stackTrace,
                stackTrace = _ref2$stackTrace === undefined ? false : _ref2$stackTrace;

            if (this.skipped(LogLevel.Trace)) return;
            this.console.log(message);
            if (stackTrace) this.console.trace();
        };

        Logger.prototype.debug = function (message) {
            var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
                _ref3$stackTrace = _ref3.stackTrace,
                stackTrace = _ref3$stackTrace === undefined ? false : _ref3$stackTrace;

            if (this.skipped(LogLevel.Debug)) return;
            this.console.log(message);
            if (stackTrace) this.console.trace();
        };

        Logger.prototype.warn = function (message) {
            var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
                _ref4$stackTrace = _ref4.stackTrace,
                stackTrace = _ref4$stackTrace === undefined ? false : _ref4$stackTrace;

            if (this.skipped(LogLevel.Warn)) return;
            this.console.warn(message);
            if (stackTrace) this.console.trace();
        };

        Logger.prototype.error = function (message) {
            if (this.skipped(LogLevel.Error)) return;
            this.console.error(message);
        };

        return Logger;
    }();
    var _console = typeof console === 'undefined' ? new NullConsole() : console;
    ALWAYS = new Logger({ console: _console, level: LogLevel.Trace });
    var LOG_LEVEL = LogLevel.Debug;
    var logger = new Logger({ console: _console, level: LOG_LEVEL });

    var objKeys = Object.keys;

    var GUID = 0;
    function initializeGuid(object) {
        return object._guid = ++GUID;
    }
    function ensureGuid(object) {
        return object._guid || initializeGuid(object);
    }

    function _classCallCheck$1(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var proto = Object.create(null, {
        // without this, we will always still end up with (new
        // EmptyObject()).constructor === Object
        constructor: {
            value: undefined,
            enumerable: false,
            writable: true
        }
    });
    function EmptyObject() {}
    EmptyObject.prototype = proto;
    function dict() {
        // let d = Object.create(null);
        // d.x = 1;
        // delete d.x;
        // return d;
        return new EmptyObject();
    }
    var DictSet = function () {
        function DictSet() {
            _classCallCheck$1(this, DictSet);

            this.dict = dict();
        }

        DictSet.prototype.add = function (obj) {
            if (typeof obj === 'string') this.dict[obj] = obj;else this.dict[ensureGuid(obj)] = obj;
            return this;
        };

        DictSet.prototype.delete = function (obj) {
            if (typeof obj === 'string') delete this.dict[obj];else if (obj._guid) delete this.dict[obj._guid];
        };

        DictSet.prototype.forEach = function (callback) {
            var dict = this.dict,
                i;

            var dictKeys = Object.keys(dict);
            for (i = 0; dictKeys.length; i++) {
                callback(dict[dictKeys[i]]);
            }
        };

        DictSet.prototype.toArray = function () {
            return Object.keys(this.dict);
        };

        return DictSet;
    }();
    var Stack = function () {
        function Stack() {
            _classCallCheck$1(this, Stack);

            this.stack = [];
            this.current = null;
        }

        Stack.prototype.toArray = function () {
            return this.stack;
        };

        Stack.prototype.push = function (item) {
            this.current = item;
            this.stack.push(item);
        };

        Stack.prototype.pop = function () {
            var item = this.stack.pop();
            var len = this.stack.length;
            this.current = len === 0 ? null : this.stack[len - 1];
            return item === undefined ? null : item;
        };

        Stack.prototype.isEmpty = function () {
            return this.stack.length === 0;
        };

        return Stack;
    }();

    function _classCallCheck$2(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var LinkedList = function () {
        function LinkedList() {
            _classCallCheck$2(this, LinkedList);

            this.clear();
        }

        LinkedList.fromSlice = function (slice) {
            var list = new LinkedList();
            slice.forEachNode(function (n) {
                return list.append(n.clone());
            });
            return list;
        };

        LinkedList.prototype.head = function () {
            return this._head;
        };

        LinkedList.prototype.tail = function () {
            return this._tail;
        };

        LinkedList.prototype.clear = function () {
            this._head = this._tail = null;
        };

        LinkedList.prototype.isEmpty = function () {
            return this._head === null;
        };

        LinkedList.prototype.toArray = function () {
            var out = [];
            this.forEachNode(function (n) {
                return out.push(n);
            });
            return out;
        };

        LinkedList.prototype.splice = function (start, end, reference) {
            var before = void 0;
            if (reference === null) {
                before = this._tail;
                this._tail = end;
            } else {
                before = reference.prev;
                end.next = reference;
                reference.prev = end;
            }
            if (before) {
                before.next = start;
                start.prev = before;
            }
        };

        LinkedList.prototype.nextNode = function (node) {
            return node.next;
        };

        LinkedList.prototype.prevNode = function (node) {
            return node.prev;
        };

        LinkedList.prototype.forEachNode = function (callback) {
            var node = this._head;
            while (node !== null) {
                callback(node);
                node = node.next;
            }
        };

        LinkedList.prototype.contains = function (needle) {
            var node = this._head;
            while (node !== null) {
                if (node === needle) return true;
                node = node.next;
            }
            return false;
        };

        LinkedList.prototype.insertBefore = function (node) {
            var reference = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            if (reference === null) return this.append(node);
            if (reference.prev) reference.prev.next = node;else this._head = node;
            node.prev = reference.prev;
            node.next = reference;
            reference.prev = node;
            return node;
        };

        LinkedList.prototype.append = function (node) {
            var tail = this._tail;
            if (tail) {
                tail.next = node;
                node.prev = tail;
                node.next = null;
            } else {
                this._head = node;
            }
            return this._tail = node;
        };

        LinkedList.prototype.pop = function () {
            if (this._tail) return this.remove(this._tail);
            return null;
        };

        LinkedList.prototype.prepend = function (node) {
            if (this._head) return this.insertBefore(node, this._head);
            return this._head = this._tail = node;
        };

        LinkedList.prototype.remove = function (node) {
            if (node.prev) node.prev.next = node.next;else this._head = node.next;
            if (node.next) node.next.prev = node.prev;else this._tail = node.prev;
            return node;
        };

        return LinkedList;
    }();
    var ListSlice = function () {
        function ListSlice(head, tail) {
            _classCallCheck$2(this, ListSlice);

            this._head = head;
            this._tail = tail;
        }

        ListSlice.toList = function (slice) {
            var list = new LinkedList();
            slice.forEachNode(function (n) {
                return list.append(n.clone());
            });
            return list;
        };

        ListSlice.prototype.forEachNode = function (callback) {
            var node = this._head;
            while (node !== null) {
                callback(node);
                node = this.nextNode(node);
            }
        };

        ListSlice.prototype.contains = function (needle) {
            var node = this._head;
            while (node !== null) {
                if (node === needle) return true;
                node = node.next;
            }
            return false;
        };

        ListSlice.prototype.head = function () {
            return this._head;
        };

        ListSlice.prototype.tail = function () {
            return this._tail;
        };

        ListSlice.prototype.toArray = function () {
            var out = [];
            this.forEachNode(function (n) {
                return out.push(n);
            });
            return out;
        };

        ListSlice.prototype.nextNode = function (node) {
            if (node === this._tail) return null;
            return node.next;
        };

        ListSlice.prototype.prevNode = function (node) {
            if (node === this._head) return null;
            return node.prev;
        };

        ListSlice.prototype.isEmpty = function () {
            return false;
        };

        return ListSlice;
    }();
    var EMPTY_SLICE = new ListSlice(null, null);

    var HAS_NATIVE_WEAKMAP = function () {
        // detect if `WeakMap` is even present
        var hasWeakMap = typeof WeakMap === 'function';
        if (!hasWeakMap) {
            return false;
        }
        var instance = new WeakMap();
        // use `Object`'s `.toString` directly to prevent us from detecting
        // polyfills as native weakmaps
        return Object.prototype.toString.call(instance) === '[object WeakMap]';
    }();

    var HAS_TYPED_ARRAYS = typeof Uint32Array !== 'undefined';
    var A = void 0;
    if (HAS_TYPED_ARRAYS) {
        A = Uint32Array;
    } else {
        A = Array;
    }
    var A$1 = A;
    var EMPTY_ARRAY = HAS_NATIVE_WEAKMAP ? Object.freeze([]) : [];

    exports.getAttrNamespace = function (attrName) {
        return WHITELIST[attrName] || null;
    };
    exports.assert = function (test, msg) {
        // if (!alreadyWarned) {
        //   alreadyWarned = true;
        //   Logger.warn("Don't leave debug assertions on in public builds");
        // }
        if (!test) {
            throw new Error(msg || "assertion failure");
        }
    };
    exports.LOGGER = logger;
    exports.Logger = Logger;
    exports.LogLevel = LogLevel;
    exports.assign = function (obj) {
        var i, assignment, keys, j, key;

        for (i = 1; i < arguments.length; i++) {
            assignment = arguments[i];

            if (assignment === null || typeof assignment !== 'object') continue;
            keys = objKeys(assignment);

            for (j = 0; j < keys.length; j++) {
                key = keys[j];

                obj[key] = assignment[key];
            }
        }
        return obj;
    };
    exports.fillNulls = function (count) {
        var arr = new Array(count),
            i;
        for (i = 0; i < count; i++) {
            arr[i] = null;
        }
        return arr;
    };
    exports.ensureGuid = ensureGuid;
    exports.initializeGuid = initializeGuid;
    exports.Stack = Stack;
    exports.DictSet = DictSet;
    exports.dict = dict;
    exports.EMPTY_SLICE = EMPTY_SLICE;
    exports.LinkedList = LinkedList;
    exports.ListNode = function ListNode(value) {
        _classCallCheck$2(this, ListNode);

        this.next = null;
        this.prev = null;
        this.value = value;
    };
    exports.ListSlice = ListSlice;
    exports.A = A$1;
    exports.EMPTY_ARRAY = EMPTY_ARRAY;
    exports.HAS_NATIVE_WEAKMAP = HAS_NATIVE_WEAKMAP;
    exports.unwrap = function (val) {
        if (val === null || val === undefined) throw new Error('Expected value to be present');
        return val;
    };
    exports.expect = function (val, message) {
        if (val === null || val === undefined) throw new Error(message);
        return val;
    };
    exports.unreachable = function () {
        return new Error('unreachable');
    };
    exports.typePos = function (lastOperand) {
        return lastOperand - 4;
    };
});