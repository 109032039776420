window.EmberENV = (function(EmberENV, extra) {
  for (var key in extra) {
    EmberENV[key] = extra[key];
  }

  return EmberENV;
})(window.EmberENV || {}, {"FEATURES":{"ds-improved-ajax":true},"EXTEND_PROTOTYPES":{"Date":false}});

var runningTests = false;


