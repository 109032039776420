enifed('ember-glimmer/protocol-for-url', ['exports', 'ember-environment', 'node-module'], function (exports, _emberEnvironment, _nodeModule) {
  'use strict';

  exports.default = function (environment) {
    var protocol = void 0;

    if (_emberEnvironment.environment.hasDOM) {
      protocol = browserProtocolForURL.call(environment, 'foobar:baz');
    }

    // Test to see if our DOM implementation parses
    // and normalizes URLs.
    if (protocol === 'foobar:') {
      // Swap in the method that doesn't do this test now that
      // we know it works.
      environment.protocolForURL = browserProtocolForURL;
    } else if (typeof URL === 'object') {
      // URL globally provided, likely from FastBoot's sandbox
      nodeURL = URL;
      environment.protocolForURL = nodeProtocolForURL;
    } else if (_nodeModule.IS_NODE) {
      // Otherwise, we need to fall back to our own URL parsing.
      // Global `require` is shadowed by Ember's loader so we have to use the fully
      // qualified `module.require`.
      nodeURL = (0, _nodeModule.require)('url');
      environment.protocolForURL = nodeProtocolForURL;
    } else {
      throw new Error('Could not find valid URL parsing mechanism for URL Sanitization');
    }
  };
  /* globals module, URL */

  var nodeURL = void 0;
  var parsingNode = void 0;

  function browserProtocolForURL(url) {
    if (!parsingNode) {
      parsingNode = document.createElement('a');
    }

    parsingNode.href = url;
    return parsingNode.protocol;
  }

  function nodeProtocolForURL(url) {
    var protocol = null;
    if (typeof url === 'string') {
      protocol = nodeURL.parse(url).protocol;
    }
    return protocol === null ? ':' : protocol;
  }
});