enifed("ember-runtime/string_registry", ["exports"], function (exports) {
  "use strict";

  exports.setStrings = function (strings) {
    STRINGS = strings;
  };
  exports.getStrings = function () {
    return STRINGS;
  };
  exports.get = function (name) {
    return STRINGS[name];
  };
  // STATE within a module is frowned upon, this exists
  // to support Ember.STRINGS but shield ember internals from this legacy global
  // API.
  var STRINGS = {};
});