define("ember-engines/components/link-to-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var owner = Ember.getOwner(this);
      (false && !(owner.mountPoint !== undefined) && Ember.assert("You attempted to use {{link-to}} within a routeless engine, this is not supported. Use {{link-to-external}} to construct links within a routeless engine. See http://ember-engines.com/guide/linking-and-external-links for more info.", owner.mountPoint !== undefined));
      if (owner.mountPoint) {
        // https://emberjs.github.io/rfcs/0459-angle-bracket-built-in-components.html
        var routeKey = 'targetRouteName' in this ? 'targetRouteName' : 'route';

        // Prepend engine mount point to targetRouteName
        this._prefixProperty(owner.mountPoint, routeKey);

        // Prepend engine mount point to current-when if set
        if (Ember.get(this, 'current-when') !== null) {
          this._prefixProperty(owner.mountPoint, 'current-when');
        }
      }
    },
    _prefixProperty: function _prefixProperty(prefix, prop) {
      var _this = this;
      var propValue = Ember.get(this, prop);

      // Sometimes `targetRouteName` will be a class
      if (Ember.typeOf(propValue) !== 'string') {
        return;
      }
      var namespacedPropValue;
      if (prop === 'current-when') {
        // `current-when` is a space-separated list of routes
        namespacedPropValue = propValue.split(' ');
        namespacedPropValue = namespacedPropValue.map(function (propValue) {
          return _this._namespacePropertyValue(prefix, propValue);
        });
        namespacedPropValue = namespacedPropValue.join(' ');
      } else {
        namespacedPropValue = this._namespacePropertyValue(prefix, propValue);
      }
      Ember.set(this, prop, namespacedPropValue);
    },
    _namespacePropertyValue: function _namespacePropertyValue(prefix, propValue) {
      if (propValue === 'application') {
        return prefix;
      } else {
        return prefix + '.' + propValue;
      }
    }
  });
  _exports.default = _default;
});