enifed('ember-runtime/mixins/action_handler', ['exports', 'ember-metal', 'ember-debug'], function (exports, _emberMetal, _emberDebug) {
  'use strict';

  exports.deprecateUnderscoreActions = function (factory) {
    Object.defineProperty(factory.prototype, '_actions', {
      configurable: true,
      enumerable: false,
      set: function () {
        false && !false && (0, _emberDebug.assert)('You cannot set `_actions` on ' + this + ', please use `actions` instead.');
      },
      get: function () {
        false && !false && (0, _emberDebug.deprecate)('Usage of `_actions` is deprecated, use `actions` instead.', false, { id: 'ember-runtime.action-handler-_actions', until: '3.0.0' });

        return (0, _emberMetal.get)(this, 'actions');
      }
    });
  };

  /**
    `Ember.ActionHandler` is available on some familiar classes including
    `Ember.Route`, `Ember.Component`, and `Ember.Controller`.
    (Internally the mixin is used by `Ember.CoreView`, `Ember.ControllerMixin`,
    and `Ember.Route` and available to the above classes through
    inheritance.)
  
    @class ActionHandler
    @namespace Ember
    @private
  */
  /**
  @module ember
  */

  var ActionHandler = _emberMetal.Mixin.create({
    mergedProperties: ['actions'],

    send: function (actionName) {
      for (_len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (this.actions && this.actions[actionName]) {
        shouldBubble = this.actions[actionName].apply(this, args) === true;

        if (!shouldBubble) {
          return;
        }
      }

      var target = (0, _emberMetal.get)(this, 'target'),
          _len,
          args,
          _key,
          shouldBubble;
      if (target) {
        false && !(typeof target.send === 'function') && (0, _emberDebug.assert)('The `target` for ' + this + ' (' + target + ') does not have a `send` method', typeof target.send === 'function');

        target.send.apply(target, arguments);
      }
    },
    willMergeMixin: function (props) {
      false && !(!props.actions || !props._actions) && (0, _emberDebug.assert)('Specifying `_actions` and `actions` in the same mixin is not supported.', !props.actions || !props._actions);

      if (props._actions) {
        false && !false && (0, _emberDebug.deprecate)('Specifying actions in `_actions` is deprecated, please use `actions` instead.', false, { id: 'ember-runtime.action-handler-_actions', until: '3.0.0' });

        props.actions = props._actions;
        delete props._actions;
      }
    }
  });

  exports.default = ActionHandler;
});