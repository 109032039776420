enifed('ember-views/mixins/child_views_support', ['exports', 'ember-utils', 'ember-metal', 'ember-views/system/utils'], function (exports, _emberUtils, _emberMetal, _utils) {
  'use strict';

  exports.default = _emberMetal.Mixin.create({
    init: function () {
      this._super.apply(this, arguments);
      (0, _utils.initChildViews)(this);
    },

    /**
      Array of child views. You should never edit this array directly.
       @property childViews
      @type Array
      @default []
      @private
    */
    childViews: (0, _emberMetal.descriptor)({
      configurable: false,
      enumerable: false,
      get: function () {
        return (0, _utils.getChildViews)(this);
      }
    }),

    appendChild: function (view) {
      this.linkChild(view);
      (0, _utils.addChildView)(this, view);
    },
    linkChild: function (instance) {
      if (!(0, _emberUtils.getOwner)(instance)) {
        (0, _emberUtils.setOwner)(instance, (0, _emberUtils.getOwner)(this));
      }
    }
  });
});