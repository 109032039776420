enifed('ember-glimmer/utils/process-args', ['exports', 'ember-utils', 'ember-glimmer/component', 'ember-glimmer/utils/references', 'ember-views', 'ember-glimmer/helpers/action'], function (exports, _emberUtils, _component, _references, _emberViews, _action) {
  'use strict';

  exports.processComponentArgs =

  // ComponentArgs takes EvaluatedNamedArgs and converts them into the
  // inputs needed by CurlyComponents (attrs and props, with mutable
  // cells, etc).
  function (namedArgs) {
    var keys = namedArgs.names,
        i,
        name,
        ref,
        value;
    var attrs = namedArgs.value();
    var props = Object.create(null);
    var args = Object.create(null);

    props[_component.ARGS] = args;

    for (i = 0; i < keys.length; i++) {
      name = keys[i];
      ref = namedArgs.get(name);
      value = attrs[name];


      if (typeof value === 'function' && value[_action.ACTION]) {
        attrs[name] = value;
      } else if (ref[_references.UPDATE]) {
        attrs[name] = new MutableCell(ref, value);
      }

      args[name] = ref;
      props[name] = value;
    }

    props.attrs = attrs;

    return props;
  };

  var REF = (0, _emberUtils.symbol)('REF');

  var MutableCell = function () {
    function MutableCell(ref, value) {

      this[_emberViews.MUTABLE_CELL] = true;
      this[REF] = ref;
      this.value = value;
    }

    MutableCell.prototype.update = function (val) {
      this[REF][_references.UPDATE](val);
    };

    return MutableCell;
  }();
});