define("ember-engines/routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildRoutes;
  function buildRoutes(callback) {
    callback.isRouteMap = true;
    return callback;
  }
});