enifed("ember-debug/testing", ["exports"], function (exports) {
  "use strict";

  exports.isTesting = function () {
    return testing;
  };
  exports.setTesting = function (value) {
    testing = !!value;
  };
  var testing = false;
});