enifed('ember-glimmer/component-managers/mount', ['exports', 'ember-babel', '@glimmer/runtime', '@glimmer/reference', 'ember-glimmer/utils/references', 'ember-glimmer/component-managers/outlet', 'ember-glimmer/component-managers/abstract', 'ember-routing'], function (exports, _emberBabel, _runtime, _reference, _references, _outlet, _abstract, _emberRouting) {
  'use strict';

  exports.MountDefinition = undefined;

  var MountManager = function (_AbstractManager) {
    (0, _emberBabel.inherits)(MountManager, _AbstractManager);

    function MountManager() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
    }

    MountManager.prototype.prepareArgs = function () {
      return null;
    };

    MountManager.prototype.create = function (environment, _ref, args, dynamicScope) {
      var name = _ref.name;

      dynamicScope.outletState = _reference.UNDEFINED_REFERENCE;

      var engine = environment.owner.buildChildEngineInstance(name);

      engine.boot();

      var bucket = { engine: engine };

      bucket.modelReference = args.named.get('model');


      return bucket;
    };

    MountManager.prototype.layoutFor = function (definition, _ref2, env) {
      var engine = _ref2.engine;

      var template = engine.lookup('template:application');
      return env.getCompiledBlock(_outlet.OutletLayoutCompiler, template);
    };

    MountManager.prototype.getSelf = function (bucket) {
      var engine = bucket.engine,
          modelReference = bucket.modelReference;

      var applicationFactory = engine.factoryFor('controller:application');
      var controllerFactory = applicationFactory || (0, _emberRouting.generateControllerFactory)(engine, 'application');
      var controller = bucket.controller = controllerFactory.create();

      var model = modelReference.value();
      bucket.modelRevision = modelReference.tag.value();
      controller.set('model', model);


      return new _references.RootReference(controller);
    };

    MountManager.prototype.getDestructor = function (_ref3) {
      var engine = _ref3.engine;

      return engine;
    };

    MountManager.prototype.didRenderLayout = function () {};

    MountManager.prototype.update = function (bucket) {
      var controller = bucket.controller,
          modelReference = bucket.modelReference,
          modelRevision = bucket.modelRevision,
          model;

      if (!modelReference.tag.validate(modelRevision)) {
        model = modelReference.value();

        bucket.modelRevision = modelReference.tag.value();
        controller.set('model', model);
      }
    };

    return MountManager;
  }(_abstract.default);

  var MOUNT_MANAGER = new MountManager();

  exports.MountDefinition = function (_ComponentDefinition) {
    (0, _emberBabel.inherits)(MountDefinition, _ComponentDefinition);

    function MountDefinition(name) {
      return (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, name, MOUNT_MANAGER, null));
    }

    return MountDefinition;
  }(_runtime.ComponentDefinition);
});