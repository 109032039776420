enifed('ember-glimmer/views/outlet', ['exports', 'ember-babel', 'ember-utils', '@glimmer/reference', 'ember-environment', 'ember-metal'], function (exports, _emberBabel, _emberUtils, _reference, _emberEnvironment, _emberMetal) {
  'use strict';

  var OutletStateReference = function () {
    function OutletStateReference(outletView) {

      this.outletView = outletView;
      this.tag = outletView._tag;
    }

    OutletStateReference.prototype.get = function (key) {
      return new ChildOutletStateReference(this, key);
    };

    OutletStateReference.prototype.value = function () {
      return this.outletView.outletState;
    };

    OutletStateReference.prototype.getOrphan = function (name) {
      return new OrphanedOutletStateReference(this, name);
    };

    OutletStateReference.prototype.update = function (state) {
      this.outletView.setOutletState(state);
    };

    return OutletStateReference;
  }();

  var OrphanedOutletStateReference = function (_OutletStateReference) {
    (0, _emberBabel.inherits)(OrphanedOutletStateReference, _OutletStateReference);

    function OrphanedOutletStateReference(root, name) {

      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _OutletStateReference.call(this, root.outletView));

      _this.root = root;
      _this.name = name;
      return _this;
    }

    OrphanedOutletStateReference.prototype.value = function () {
      var rootState = this.root.value();

      var orphans = rootState.outlets.main.outlets.__ember_orphans__;

      if (!orphans) {
        return null;
      }

      var matched = orphans.outlets[this.name];

      if (!matched) {
        return null;
      }

      var state = Object.create(null);
      state[matched.render.outlet] = matched;
      matched.wasUsed = true;
      return { outlets: state };
    };

    return OrphanedOutletStateReference;
  }(OutletStateReference);

  var ChildOutletStateReference = function () {
    function ChildOutletStateReference(parent, key) {

      this.parent = parent;
      this.key = key;
      this.tag = parent.tag;
    }

    ChildOutletStateReference.prototype.get = function (key) {
      return new ChildOutletStateReference(this, key);
    };

    ChildOutletStateReference.prototype.value = function () {
      return this.parent.value()[this.key];
    };

    return ChildOutletStateReference;
  }();

  var OutletView = function () {
    OutletView.extend = function (injections) {
      return function (_OutletView) {
        (0, _emberBabel.inherits)(_class, _OutletView);

        function _class() {
          return (0, _emberBabel.possibleConstructorReturn)(this, _OutletView.apply(this, arguments));
        }

        _class.create = function (options) {
          if (options) {
            return _OutletView.create.call(this, (0, _emberUtils.assign)({}, injections, options));
          } else {
            return _OutletView.create.call(this, injections);
          }
        };

        return _class;
      }(OutletView);
    };

    OutletView.reopenClass = function (injections) {
      (0, _emberUtils.assign)(this, injections);
    };

    OutletView.create = function (options) {
      var _environment = options._environment,
          renderer = options.renderer,
          template = options.template;

      var owner = options[_emberUtils.OWNER];
      return new OutletView(_environment, renderer, owner, template);
    };

    function OutletView(_environment, renderer, owner, template) {

      this._environment = _environment;
      this.renderer = renderer;
      this.owner = owner;
      this.template = template;
      this.outletState = null;
      this._tag = new _reference.DirtyableTag();
    }

    OutletView.prototype.appendTo = function (selector) {
      var env = this._environment || _emberEnvironment.environment;
      var target = void 0;

      if (env.hasDOM) {
        target = typeof selector === 'string' ? document.querySelector(selector) : selector;
      } else {
        target = selector;
      }

      _emberMetal.run.schedule('render', this.renderer, 'appendOutletView', this, target);
    };

    OutletView.prototype.rerender = function () {};

    OutletView.prototype.setOutletState = function (state) {
      this.outletState = {
        outlets: {
          main: state
        },
        render: {
          owner: undefined,
          into: undefined,
          outlet: 'main',
          name: '-top-level',
          controller: undefined,
          ViewClass: undefined,
          template: undefined
        }
      };
      this._tag.dirty();
    };

    OutletView.prototype.toReference = function () {
      return new OutletStateReference(this);
    };

    OutletView.prototype.destroy = function () {};

    return OutletView;
  }();

  exports.default = OutletView;
});