enifed('ember-runtime/ext/string', ['ember-environment', 'ember-runtime/system/string'], function (_emberEnvironment, _string) {
  'use strict';

  /**
  @module @ember/string
  */

  var StringPrototype = String.prototype;

  if (_emberEnvironment.ENV.EXTEND_PROTOTYPES.String) {
    /**
      See [Ember.String.fmt](/api/classes/Ember.String.html#method_fmt).
       @method fmt
      @for @ember/string
      @static
      @private
      @deprecated
    */
    StringPrototype.fmt = function () {
      var _len, args, _key;

      for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return (0, _string.fmt)(this, args);
    };

    /**
      See [Ember.String.w](/api/classes/Ember.String.html#method_w).
       @method w
      @for @ember/string
      @static
      @private
    */
    StringPrototype.w = function () {
      return (0, _string.w)(this);
    };

    /**
      See [Ember.String.loc](/api/classes/Ember.String.html#method_loc).
       @method loc
      @for @ember/string
      @static
      @private
    */
    StringPrototype.loc = function () {
      var _len2, args, _key2;

      for (_len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return (0, _string.loc)(this, args);
    };

    /**
      See [Ember.String.camelize](/api/classes/Ember.String.html#method_camelize).
       @method camelize
      @for @ember/string
      @static
      @private
    */
    StringPrototype.camelize = function () {
      return (0, _string.camelize)(this);
    };

    /**
      See [Ember.String.decamelize](/api/classes/Ember.String.html#method_decamelize).
       @method decamelize
      @for @ember/string
      @static
      @private
    */
    StringPrototype.decamelize = function () {
      return (0, _string.decamelize)(this);
    };

    /**
      See [Ember.String.dasherize](/api/classes/Ember.String.html#method_dasherize).
       @method dasherize
      @for @ember/string
      @static
      @private
    */
    StringPrototype.dasherize = function () {
      return (0, _string.dasherize)(this);
    };

    /**
      See [Ember.String.underscore](/api/classes/Ember.String.html#method_underscore).
       @method underscore
      @for @ember/string
      @static
      @private
    */
    StringPrototype.underscore = function () {
      return (0, _string.underscore)(this);
    };

    /**
      See [Ember.String.classify](/api/classes/Ember.String.html#method_classify).
       @method classify
      @for @ember/string
      @static
      @private
    */
    StringPrototype.classify = function () {
      return (0, _string.classify)(this);
    };

    /**
      See [Ember.String.capitalize](/api/classes/Ember.String.html#method_capitalize).
       @method capitalize
      @for @ember/string
      @static
      @private
    */
    StringPrototype.capitalize = function () {
      return (0, _string.capitalize)(this);
    };
  }
});