enifed('ember-glimmer/template', ['exports', 'ember-utils', '@glimmer/runtime'], function (exports, _emberUtils, _runtime) {
  'use strict';

  exports.default = function (json) {
    var factory = (0, _runtime.templateFactory)(json);

    return {
      id: factory.id,
      meta: factory.meta,
      create: function (props) {
        return factory.create(props.env, { owner: props[_emberUtils.OWNER] });
      }
    };
  };
});