enifed("@glimmer/wire-format", ["exports"], function (exports) {
    "use strict";

    var Opcodes;
    (function (Opcodes) {
        // Statements
        Opcodes[Opcodes["Text"] = 0] = "Text";
        Opcodes[Opcodes["Append"] = 1] = "Append";
        Opcodes[Opcodes["Comment"] = 2] = "Comment";
        Opcodes[Opcodes["Modifier"] = 3] = "Modifier";
        Opcodes[Opcodes["Block"] = 4] = "Block";
        Opcodes[Opcodes["Component"] = 5] = "Component";
        Opcodes[Opcodes["OpenElement"] = 6] = "OpenElement";
        Opcodes[Opcodes["FlushElement"] = 7] = "FlushElement";
        Opcodes[Opcodes["CloseElement"] = 8] = "CloseElement";
        Opcodes[Opcodes["StaticAttr"] = 9] = "StaticAttr";
        Opcodes[Opcodes["DynamicAttr"] = 10] = "DynamicAttr";
        Opcodes[Opcodes["Yield"] = 11] = "Yield";
        Opcodes[Opcodes["Partial"] = 12] = "Partial";
        Opcodes[Opcodes["DynamicArg"] = 13] = "DynamicArg";
        Opcodes[Opcodes["StaticArg"] = 14] = "StaticArg";
        Opcodes[Opcodes["TrustingAttr"] = 15] = "TrustingAttr";
        Opcodes[Opcodes["Debugger"] = 16] = "Debugger";
        Opcodes[Opcodes["ClientSideStatement"] = 17] = "ClientSideStatement";
        // Expressions
        Opcodes[Opcodes["Unknown"] = 18] = "Unknown";
        Opcodes[Opcodes["Get"] = 19] = "Get";
        Opcodes[Opcodes["MaybeLocal"] = 20] = "MaybeLocal";
        Opcodes[Opcodes["FixThisBeforeWeMerge"] = 21] = "FixThisBeforeWeMerge";
        Opcodes[Opcodes["HasBlock"] = 22] = "HasBlock";
        Opcodes[Opcodes["HasBlockParams"] = 23] = "HasBlockParams";
        Opcodes[Opcodes["Undefined"] = 24] = "Undefined";
        Opcodes[Opcodes["Helper"] = 25] = "Helper";
        Opcodes[Opcodes["Concat"] = 26] = "Concat";
        Opcodes[Opcodes["ClientSideExpression"] = 27] = "ClientSideExpression";
    })(Opcodes || (exports.Ops = Opcodes = {}));

    function is(variant) {
        return function (value) {
            return Array.isArray(value) && value[0] === variant;
        };
    }
    var Expressions;
    (function (Expressions) {
        Expressions.isUnknown = is(Opcodes.Unknown);
        Expressions.isGet = is(Opcodes.Get);
        Expressions.isConcat = is(Opcodes.Concat);
        Expressions.isHelper = is(Opcodes.Helper);
        Expressions.isHasBlock = is(Opcodes.HasBlock);
        Expressions.isHasBlockParams = is(Opcodes.HasBlockParams);
        Expressions.isUndefined = is(Opcodes.Undefined);
        Expressions.isClientSide = is(Opcodes.ClientSideExpression);
        Expressions.isMaybeLocal = is(Opcodes.MaybeLocal);

        Expressions.isPrimitiveValue = function (value) {
            if (value === null) {
                return true;
            }
            return typeof value !== 'object';
        };
    })(Expressions || (exports.Expressions = Expressions = {}));
    var Statements;
    (function (Statements) {
        Statements.isText = is(Opcodes.Text);
        Statements.isAppend = is(Opcodes.Append);
        Statements.isComment = is(Opcodes.Comment);
        Statements.isModifier = is(Opcodes.Modifier);
        Statements.isBlock = is(Opcodes.Block);
        Statements.isComponent = is(Opcodes.Component);
        Statements.isOpenElement = is(Opcodes.OpenElement);
        Statements.isFlushElement = is(Opcodes.FlushElement);
        Statements.isCloseElement = is(Opcodes.CloseElement);
        Statements.isStaticAttr = is(Opcodes.StaticAttr);
        Statements.isDynamicAttr = is(Opcodes.DynamicAttr);
        Statements.isYield = is(Opcodes.Yield);
        Statements.isPartial = is(Opcodes.Partial);
        Statements.isDynamicArg = is(Opcodes.DynamicArg);
        Statements.isStaticArg = is(Opcodes.StaticArg);
        Statements.isTrustingAttr = is(Opcodes.TrustingAttr);
        Statements.isDebugger = is(Opcodes.Debugger);
        Statements.isClientSide = is(Opcodes.ClientSideStatement);
        function isAttribute(val) {
            return val[0] === Opcodes.StaticAttr || val[0] === Opcodes.DynamicAttr || val[0] === Opcodes.TrustingAttr;
        }
        Statements.isAttribute = isAttribute;
        function isArgument(val) {
            return val[0] === Opcodes.StaticArg || val[0] === Opcodes.DynamicArg;
        }
        Statements.isArgument = isArgument;

        Statements.isParameter = function (val) {
            return isAttribute(val) || isArgument(val);
        };

        Statements.getParameterName = function (s) {
            return s[1];
        };
    })(Statements || (exports.Statements = Statements = {}));

    exports.is = is;
    exports.Expressions = Expressions;
    exports.Statements = Statements;
    exports.Ops = Opcodes;
});