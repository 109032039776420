enifed('ember-glimmer/syntax/dynamic-component', ['exports', '@glimmer/runtime', '@glimmer/reference', 'ember-debug', 'ember-glimmer/syntax/utils'], function (exports, _runtime, _reference, _emberDebug, _utils) {
  'use strict';

  exports.dynamicComponentMacro = function (params, hash, _default, inverse, builder) {
    var definitionArgs = [params.slice(0, 1), null, null, null];
    var args = [params.slice(1), (0, _utils.hashToArgs)(hash), null, null];
    builder.component.dynamic(definitionArgs, dynamicComponentFor, args);
    return true;
  };
  exports.blockComponentMacro = function (params, hash, _default, inverse, builder) {
    var definitionArgs = [params.slice(0, 1), null, null, null];
    var args = [params.slice(1), (0, _utils.hashToArgs)(hash), _default, inverse];
    builder.component.dynamic(definitionArgs, dynamicComponentFor, args);
    return true;
  };
  exports.inlineComponentMacro = function (name, params, hash, builder) {
    var definitionArgs = [params.slice(0, 1), null, null, null];
    var args = [params.slice(1), (0, _utils.hashToArgs)(hash), null, null];
    builder.component.dynamic(definitionArgs, dynamicComponentFor, args);
    return true;
  };

  function dynamicComponentFor(vm, args, meta) {
    var env = vm.env;
    var nameRef = args.positional.at(0);

    return new DynamicComponentReference({ nameRef: nameRef, env: env, meta: meta });
  }

  var DynamicComponentReference = function () {
    function DynamicComponentReference(_ref) {
      var nameRef = _ref.nameRef,
          env = _ref.env,
          meta = _ref.meta,
          args = _ref.args;


      this.tag = nameRef.tag;
      this.nameRef = nameRef;
      this.env = env;
      this.meta = meta;
      this.args = args;
    }

    DynamicComponentReference.prototype.value = function () {
      var env = this.env,
          nameRef = this.nameRef,
          meta = this.meta,
          definition;

      var nameOrDef = nameRef.value();

      if (typeof nameOrDef === 'string') {
        definition = env.getComponentDefinition(nameOrDef, meta);


        false && !definition && (0, _emberDebug.assert)('Could not find component named "' + nameOrDef + '" (no component or template with that name was found)', definition);

        return definition;
      } else if ((0, _runtime.isComponentDefinition)(nameOrDef)) {
        return nameOrDef;
      } else {
        return null;
      }
    };

    DynamicComponentReference.prototype.get = function () {
      return _reference.UNDEFINED_REFERENCE;
    };

    return DynamicComponentReference;
  }();
});