enifed("@glimmer/node", ["exports", "@glimmer/runtime"], function (exports, _runtime) {
    "use strict";

    exports.NodeDOMTreeConstruction = undefined;

    function _defaults(obj, defaults) {
        var keys = Object.getOwnPropertyNames(defaults),
            i,
            key,
            value;for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = Object.getOwnPropertyDescriptor(defaults, key);
            if (value && value.configurable && obj[key] === undefined) {
                Object.defineProperty(obj, key, value);
            }
        }return obj;
    }

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : _defaults(subClass, superClass);
    }

    var NodeDOMTreeConstruction = function (_DOMTreeConstruction) {
        _inherits(NodeDOMTreeConstruction, _DOMTreeConstruction);

        function NodeDOMTreeConstruction(doc) {
            _classCallCheck(this, NodeDOMTreeConstruction);

            return _possibleConstructorReturn(this, _DOMTreeConstruction.call(this, doc));
        }
        // override to prevent usage of `this.document` until after the constructor


        NodeDOMTreeConstruction.prototype.setupUselessElement = function () {};

        NodeDOMTreeConstruction.prototype.insertHTMLBefore = function (parent, reference, html) {
            var prev = reference ? reference.previousSibling : parent.lastChild;
            var raw = this.document.createRawHTMLSection(html);
            parent.insertBefore(raw, reference);
            var first = prev ? prev.nextSibling : parent.firstChild;
            var last = reference ? reference.previousSibling : parent.lastChild;
            return new _runtime.ConcreteBounds(parent, first, last);
        };
        // override to avoid SVG detection/work when in node (this is not needed in SSR)


        NodeDOMTreeConstruction.prototype.createElement = function (tag) {
            return this.document.createElement(tag);
        };
        // override to avoid namespace shenanigans when in node (this is not needed in SSR)


        NodeDOMTreeConstruction.prototype.setAttribute = function (element, name, value) {
            element.setAttribute(name, value);
        };

        return NodeDOMTreeConstruction;
    }(_runtime.DOMTreeConstruction);

    exports.NodeDOMTreeConstruction = NodeDOMTreeConstruction;
});