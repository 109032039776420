enifed('ember-views/component_lookup', ['exports', 'ember-debug', 'ember-runtime'], function (exports, _emberDebug, _emberRuntime) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    componentFor: function (name, owner, options) {
      false && !~name.indexOf('-') && (0, _emberDebug.assert)('You cannot use \'' + name + '\' as a component name. Component names must contain a hyphen.', ~name.indexOf('-'));

      return owner.factoryFor('component:' + name, options);
    },
    layoutFor: function (name, owner, options) {
      false && !~name.indexOf('-') && (0, _emberDebug.assert)('You cannot use \'' + name + '\' as a component name. Component names must contain a hyphen.', ~name.indexOf('-'));

      return owner.lookup('template:components/' + name, options);
    }
  });
});