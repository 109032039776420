enifed('ember-glimmer/setup-registry', ['exports', 'ember-babel', 'ember-environment', 'container', 'ember-glimmer/renderer', 'ember-glimmer/dom', 'ember-glimmer/views/outlet', 'ember-glimmer/components/text_field', 'ember-glimmer/components/text_area', 'ember-glimmer/components/checkbox', 'ember-glimmer/components/link-to', 'ember-glimmer/component', 'ember-glimmer/templates/component', 'ember-glimmer/templates/root', 'ember-glimmer/templates/outlet', 'ember-glimmer/environment'], function (exports, _emberBabel, _emberEnvironment, _container, _renderer, _dom, _outlet, _text_field, _text_area, _checkbox, _linkTo, _component, _component2, _root, _outlet2, _environment) {
  'use strict';

  exports.setupApplicationRegistry = function (registry) {
    registry.injection('service:-glimmer-environment', 'appendOperations', 'service:-dom-tree-construction');
    registry.injection('renderer', 'env', 'service:-glimmer-environment');

    registry.register((0, _container.privatize)(_templateObject), _root.default);
    registry.injection('renderer', 'rootTemplate', (0, _container.privatize)(_templateObject));

    registry.register('renderer:-dom', _renderer.InteractiveRenderer);
    registry.register('renderer:-inert', _renderer.InertRenderer);

    if (_emberEnvironment.environment.hasDOM) {
      registry.injection('service:-glimmer-environment', 'updateOperations', 'service:-dom-changes');
    }

    registry.register('service:-dom-changes', {
      create: function (_ref) {
        var document = _ref.document;

        return new _dom.DOMChanges(document);
      }
    });

    registry.register('service:-dom-tree-construction', {
      create: function (_ref2) {
        var document = _ref2.document;

        var Implementation = _emberEnvironment.environment.hasDOM ? _dom.DOMTreeConstruction : _dom.NodeDOMTreeConstruction;
        return new Implementation(document);
      }
    });
  };
  exports.setupEngineRegistry = function (registry) {
    registry.register('view:-outlet', _outlet.default);
    registry.register('template:-outlet', _outlet2.default);
    registry.injection('view:-outlet', 'template', 'template:-outlet');

    registry.injection('service:-dom-changes', 'document', 'service:-document');
    registry.injection('service:-dom-tree-construction', 'document', 'service:-document');

    registry.register((0, _container.privatize)(_templateObject2), _component2.default);

    registry.register('service:-glimmer-environment', _environment.default);
    registry.injection('template', 'env', 'service:-glimmer-environment');

    registry.optionsForType('helper', { instantiate: false });

    registry.register('component:-text-field', _text_field.default);
    registry.register('component:-text-area', _text_area.default);
    registry.register('component:-checkbox', _checkbox.default);
    registry.register('component:link-to', _linkTo.default);
    registry.register((0, _container.privatize)(_templateObject3), _component.default);
  };

  var _templateObject = (0, _emberBabel.taggedTemplateLiteralLoose)(['template:-root'], ['template:-root']),
      _templateObject2 = (0, _emberBabel.taggedTemplateLiteralLoose)(['template:components/-default'], ['template:components/-default']),
      _templateObject3 = (0, _emberBabel.taggedTemplateLiteralLoose)(['component:-default'], ['component:-default']);
});