define("ember-engines/components/link-to-external-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.LinkComponent.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var owner = Ember.getOwner(this);
      if (owner.mountPoint) {
        // https://emberjs.github.io/rfcs/0459-angle-bracket-built-in-components.html
        var routeKey = 'targetRouteName' in this ? 'targetRouteName' : 'route';
        var routeName = Ember.get(this, routeKey);
        var externalRoute = owner._getExternalRoute(routeName);
        Ember.set(this, routeKey, externalRoute);
      }
    }
  });
  _exports.default = _default;
});